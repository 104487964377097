import { BoReference } from '@shared/bos/bo-reference'
import { BoVisitor } from '@shared/bos/bo-visitor'
import type { TsCodeType, TsTypeType } from '@shared/types'
import { jsonMember, jsonObject } from 'typedjson'
import { ScreenEditorInput } from '../decorators/screen-editor-input'
import { TrackByFunctionNames, TrackByFunctions } from '../logic/for-block'
import { BlockWithDataSource, BlockWithPagedDataSource } from './block-with-data-source'

export const BlockDataSources = {
	entity: 'Entity on ServerDataStore',
	array: 'Array of items',
	tasks: 'Process tasks',
} as const
export type BlockDataSource = keyof typeof BlockDataSources

@jsonObject
export class BlockDataSourceDefinition {
	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'dropdown',
		order: 1,
		label: 'Data source type',
		fieldset: () => 'Data source',
		options: Object.entries(BlockDataSources),
		isMandatory: () => true,
	})
	sourceType: BlockDataSource = 'entity'

	@jsonMember
	@ScreenEditorInput<BlockWithDataSource>({
		inputType: 'boRef',
		order: 10,
		label: 'Entity',
		fieldset: () => 'Data source',
		allowedBoTypes: ['Entity'],
		includeImportedModules: true,
		isVisible: blocks => blocks[0]?.dataSource.sourceType == 'entity',
		isMandatory: () => true,
	})
	entityRef: BoReference = new BoReference()

	@jsonMember
	@ScreenEditorInput<BlockWithDataSource>({
		inputType: 'boRefSubtype',
		order: 11,
		label: 'Entity subset',
		fieldset: () => 'Data source',
		boRef: blocks => blocks[0].dataSource.entityRef,
		isVisible: blocks => blocks[0]?.dataSource.sourceType == 'entity',
		isMandatory: () => true,
	})
	entitySubsetName: string = ''

	@jsonMember
	@ScreenEditorInput<BlockWithDataSource>({
		inputType: 'boRef',
		order: 12,
		label: 'ServerDataStore',
		fieldset: () => 'Data source',
		allowedBoTypes: ['ServerDataStore'],
		includeImportedModules: true,
		isVisible: blocks => blocks[0]?.dataSource.sourceType == 'entity',
		isMandatory: () => true,
	})
	serverDataStoreRef: BoReference = new BoReference()

	@jsonMember(String)
	@ScreenEditorInput<BlockWithDataSource>({
		inputType: 'code',
		order: 13,
		label: 'Find options',
		fieldset: () => 'Data source',
		codeLanguage: 'ts',
		tsReturnType: (blocks) => `Sys.Types.FindManyOptions<${blocks[0]?.dataSource.getItemTsType()}>`,
		editorSize: 'normal',
		spanColumns: 'span 2',
		isBinding: false,
		isVisible: blocks => blocks[0]?.dataSource.sourceType == 'entity',
	})
	additionalFindOptionsCode: TsCodeType = ''

	@jsonMember(String)
	@ScreenEditorInput<BlockWithDataSource>({
		inputType: 'code',
		order: 20,
		label: 'Item type',
		fieldset: () => 'Data source',
		codeLanguage: 'ts',
		tsReturnType: () => 'any',
		editorSize: 'singleline',
		isBinding: false,
		isVisible: blocks => blocks[0]?.dataSource.sourceType == 'array',
		isMandatory: () => true,
	})
	itemTsType: TsTypeType = 'string'

	@jsonMember(String)
	@ScreenEditorInput<BlockWithDataSource>({
		inputType: 'code',
		order: 21,
		label: 'Array of items',
		fieldset: () => 'Data source',
		codeLanguage: 'ts',
		tsReturnType: (blocks) => {
			const type = `${blocks[0]?.dataSource.itemTsType}`
			return `(${type})[] | ((paging: Sys.Types.PagingDefinition) => (${type})[] | Promise<${type}[]>)`
		},
		editorSize: 'singleline',
		isBinding: false,
		isVisible: blocks => blocks[0]?.dataSource.sourceType == 'array',
		isMandatory: () => true,
	})
	collectionCode: TsCodeType = '[]'

	@jsonMember(Boolean)
	@ScreenEditorInput<BlockWithDataSource>({
		inputType: 'checkbox',
		order: 40,
		label: 'Include tasks currently assigned to the user',
		fieldset: () => 'Process Tasks',
		isBinding: false,
		isVisible: blocks => blocks[0]?.dataSource.sourceType == 'tasks',
		isMandatory: () => true,
	})
	includeAssignedTasks: boolean = true

	@jsonMember(Boolean)
	@ScreenEditorInput<BlockWithDataSource>({
		inputType: 'checkbox',
		order: 40,
		label: 'Include tasks the user is allowed to take over',
		fieldset: () => 'Process Tasks',
		isBinding: false,
		isVisible: blocks => blocks[0]?.dataSource.sourceType == 'tasks',
	})
	includeUserAllowedTasks: boolean = true

	@jsonMember(Boolean)
	@ScreenEditorInput<BlockWithDataSource>({
		inputType: 'checkbox',
		order: 40,
		label: 'Include tasks the user\'s roles are allowed to take over',
		fieldset: () => 'Process Tasks',
		isBinding: false,
		isVisible: blocks => blocks[0]?.dataSource.sourceType == 'tasks',
	})
	includeRoleAllowedTasks: boolean = false

	@jsonMember(Boolean)
	@ScreenEditorInput<BlockWithDataSource>({
		inputType: 'checkbox',
		order: 40,
		label: 'Include tasks the user\'s groups are allowed to take over',
		fieldset: () => 'Process Tasks',
		isBinding: false,
		isVisible: blocks => blocks[0]?.dataSource.sourceType == 'tasks',
	})
	includeGroupAllowedTasks: boolean = false

	@jsonMember(String)
	@ScreenEditorInput<BlockWithDataSource>({
		inputType: 'input',
		order: 30,
		label: 'Loop variable',
		fieldset: () => 'Data source',
		contentType: 'text',
		elementType: 'input',
	})
	loopVariableDefinition: TsTypeType = 'item'

	
	@jsonMember(String)
	@ScreenEditorInput<BlockWithDataSource>({
		inputType: 'input',
		order: 31,
		label: 'Index variable (0, 1, 2, ...)',
		fieldset: () => 'Data source',
		contentType: 'text',
		elementType: 'input',
	})
	indexVariableDefinition: TsTypeType = 'index'

	@jsonMember(String)
	@ScreenEditorInput<BlockWithPagedDataSource>({
		inputType: 'code',
		order: 41,
		label: blocks => blocks[0]?.dataSource.usePaging ? 'Page size' : 'Max row count',
		tab: 'Data Management',
		fieldset: () => 'Data source',
		codeLanguage: 'ts',
		tsReturnType: () => `number`,
		editorSize: 'singleline',
		isBinding: false,
	})
	pageSizeCode: TsCodeType = '20'

	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'dropdown',
		order: 7,
		tab: 'Data Management',
		fieldset: () => 'Data source',
		label: 'Track by',
		options: TrackByFunctions.map(f => [f, TrackByFunctionNames[f]])
	})
	trackBy: typeof TrackByFunctions[number] = 'trackByAutomatic'

	getItemTsType() {
		switch(this.sourceType) {
			case 'entity':
				if(!this.entityRef.isFullyDefined()) return 'any'
				return this.entityRef.getQualifiedName(this.entitySubsetName)

			case 'array':
				return this.itemTsType

			case 'tasks':
				return 'Sys.Types.UserTaskToken'
		}
	}
}

@jsonObject
export class PagedBlockDataSourceDefinition extends BlockDataSourceDefinition {
	@jsonMember
	@ScreenEditorInput({
		inputType: 'checkbox',
		order: 40,
		label: 'Use paging',
		tab: 'Data Management',
	})
	usePaging: boolean = true

	@jsonMember(String)
	@ScreenEditorInput<BlockWithPagedDataSource>({
		inputType: 'code',
		order: 42,
		label: 'Page size options',
		tab: 'Data Management',
		codeLanguage: 'ts',
		tsReturnType: () => `number[]`,
		editorSize: 'singleline',
		isBinding: false,
		isVisible: blocks => blocks[0]?.dataSource.usePaging,
	})
	pageSizeOptionsCode: TsCodeType = '[10, 20, 50]'
}