import { FormInputBlock } from './form-input-block';
import { DataUtil } from '@shared/util/data-util';
import { BoReference } from '@shared/bos/bo-reference';
import { Block } from '../block'
import { jsonObject, jsonMember, jsonArrayMember, jsonMapMember } from 'typedjson'
import { BoVisitor } from '@shared/bos/bo-visitor'
import { FormControlElement, FormElement } from './form-elements'
import { ScreenEditorInput } from '../decorators/screen-editor-input'

@jsonObject({ name: 'FormBlock' })
export class FormBlock extends Block {
	@jsonMember
	@ScreenEditorInput({
		inputType: 'input',
		order: 1,
		label: 'Form variable name',
		contentType: 'text',
		elementType: 'input',
	})
	formVariable: string = ''

	@jsonMapMember(String, BoReference)
	variables: Map<string, BoReference> = new Map()

	constructor(init: Partial<FormBlock> = {}) {
		super()
		this.init(init)
	}

	getFormElements() {
		const els: FormElement[] = []

		this.visit((block, parent) => {
			if(block instanceof FormInputBlock) {
				const [variable, bindingPropertyName] = block.bindingCode?.split('.') ?? []
				const boRef = this.variables.get(variable)

				els.push(new FormControlElement({
					variable,
					boRef,
					bindingPropertyName,
					block,
					parentBlock: parent ?? undefined,
				}))
			}
		}, null, 0, 'first')

		return els
	}

	getVariableScope(includeDeclarationVariables: boolean): Record<string, string> {
		const superScope = super.getVariableScope(includeDeclarationVariables)

		return this.formVariable ? {
			...superScope,
			[this.formVariable]: 'Sys.Types.Form',
		} : superScope
	}

	visitThisWithBoVisitor(visitor: BoVisitor, pathPrefix: (string | number)[]): void {
		for(const [variable, boRef] of this.variables.entries()) {
			visitor.visitBoReference(boRef, ['variables', variable])
		}
	}

	getEditorCategory(): string {
		return 'Additional Form Fields'
	}
}
