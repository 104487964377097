import { Block } from '../block'
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { ScreenEditorInput } from '../decorators/screen-editor-input'

@jsonObject({ name: 'CardBlock' })
export class CardBlock extends Block {
	@ScreenEditorInput({
		inputType: 'code',
		order: 1,
		label: 'Title text (in header)',
		codeLanguage: 'ts',
		tsReturnType: () => 'string',
		editorSize: 'singleline',
		isBinding: false,
		alternativeContentAreaName: 'title'
	})
	@jsonMember(String) titleCode: string = ""

	@ScreenEditorInput({
		inputType: 'code',
		order: 2,
		label: 'Sub-title text (in header)',
		codeLanguage: 'ts',
		tsReturnType: () => 'string',
		editorSize: 'singleline',
		isBinding: false,
		alternativeContentAreaName: 'subtitle'
	})
	@jsonMember(String) subtitleCode: string = ""

	@ScreenEditorInput({
		inputType: 'code',
		order: 3,
		label: 'Avatar text (top-left)',
		codeLanguage: 'ts',
		tsReturnType: () => 'string',
		editorSize: 'singleline',
		isBinding: false,
		alternativeContentAreaName: 'avatar'
	})
	@jsonMember(String) avatarCode: string = ""

	@ScreenEditorInput({
		inputType: 'code',
		order: 4,
		label: 'Text before content (full-width)',
		tooltip: () => ({ text: 'This is useful to show full-width elements before the content area (which has a padding on it), e.g. a full-width image' }),
		codeLanguage: 'ts',
		tsReturnType: () => 'string',
		editorSize: 'singleline',
		isBinding: false,
		alternativeContentAreaName: 'beforeContent'
	})
	@jsonMember(String) beforeContentCode: string = ""

	@ScreenEditorInput({
		inputType: 'code',
		order: 4,
		label: 'Actions text (below content)',
		codeLanguage: 'ts',
		tsReturnType: () => 'string',
		editorSize: 'singleline',
		isBinding: false,
		alternativeContentAreaName: 'actions'
	})
	@jsonMember(String) actionsCode: string = ""

	@ScreenEditorInput({
		inputType: 'code',
		order: 5,
		label: 'Footer text',
		codeLanguage: 'ts',
		tsReturnType: () => 'string',
		editorSize: 'singleline',
		isBinding: false,
		alternativeContentAreaName: 'footer'
	})
	@jsonMember(String) footerCode: string = ""

	constructor(init: Partial<CardBlock> = {}) {
		super()
		this.init(init)
	}

	getTitleBackgroundAndBorderColor(): string {
		return 'black'
	}

	getTitleColor(): string {
		return 'white'
	}

	getEditorCategory(): string {
		return 'Layout'
	}
}
