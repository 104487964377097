import type { Patch } from 'immer'
import type { ColumnType } from 'typeorm';
import type { Parameter } from './script/parameter'
import type { ClientLogLevel, FindManyOptions, PagedData, PagingDefinition } from './app-types'
import { Lock } from './lock/lock'
import * as z from 'zod'
import { UserIdSchema, UserIdType } from './user-id'
import { BoReference } from './bos/bo-reference'
/** 
 * @deprecated
 */
export type Newable<T, A extends any[] = any[]> = { new (...args: A): T }

export const BusinessObjectTypes = [
	'Module',
	'DataStore',
	'Entity',
	'StaticEntity',
	'Screen',
	'Process',
	'ConfigurationItem',
	'Label',
	'Role',
	'Permission',
	'DataType',
	'ServerDataStore',
	'ServiceInterface',
	'Resource',
	'Language',
	'AccessControl',
	'Trigger',
	'Theme',
	'UserDefinedConfiguration',
] as const
export const BusinessObjectInfos: Record<BusinessObjectTypeType, {
	pluralName: string,
	hasEditor: boolean,
}> = {
	Module: { pluralName: 'Modules', hasEditor: true },
	DataStore: { pluralName: 'DataStores', hasEditor: true },
	Entity: { pluralName: 'Entities', hasEditor: true },
	StaticEntity: { pluralName: 'StaticEntities', hasEditor: true },
	Screen: { pluralName: 'Screens', hasEditor: true },
	Process: { pluralName: 'Processes', hasEditor: true },
	ConfigurationItem: { pluralName: 'ConfigurationItems', hasEditor: true },
	Label: { pluralName: 'Labels', hasEditor: true },
	Role: { pluralName: 'Roles', hasEditor: true },
	Permission: { pluralName: 'Permissions', hasEditor: true },
	DataType: { pluralName: 'DataTypes', hasEditor: true },
	ServerDataStore: { pluralName: 'ServerDataStores', hasEditor: true },
	ServiceInterface: { pluralName: 'ServiceInterfaces', hasEditor: true },
	Resource: { pluralName: 'Resources', hasEditor: true },
	Language: { pluralName: 'Languages', hasEditor: false },
	AccessControl: { pluralName: 'AccessControls', hasEditor: true },
	Trigger: { pluralName: 'Triggers', hasEditor: true },
	Theme: { pluralName: 'Themes', hasEditor: true },
	UserDefinedConfiguration: { pluralName: 'UserDefinedConfigurations', hasEditor: true },
}

export interface BusinessObjectItem {
	__type?: string
	type?: string
}

export type BoParamsType = {
	branchName: BranchNameType;
	moduleId: ModuleIdType;
	boType: BusinessObjectTypeType;
	boId: BusinessObjectIdType;
}
  
export type BusinessObjectTypeType =
| typeof BusinessObjectTypes[number]
export type BusinessObjectTypesOrAll = BusinessObjectTypeType[] | '*'
export type BusinessObjectIdType = string
export type ModuleIdType = string
export type ModuleBundleType = string
export type BranchNameType = string
export * from './user-id'

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'HEAD' | 'JSONP' | 'OPTIONS'

export const MAXLENS = {
	moduleId: 50,
	moduleBundle: 100,
	moduleName: 256,
	boId: 100,
	boType: 25,
	branchName: 50,
	propertyName: 100,
	userName: 50,
	userFirstLastName: 50,
	email: 100,
	phoneNumber: 100,
	password: 100,
	passwordHash: 60,
	jsonConfig: 4000,
	filePath: 2048,
	lockObjectType: 20,
	lockObjectName: 150,
	lockLevel: 20,
	threadName: 30,
	logType: 10,
	logComponent: 30,
	extensionId: 50,
	mimeType: 100,
	genericName: 50,
	certificatePem: 10000,
	publicKeyPem: 1000,
	privateKeyPem: 4000,
	ipV4Address: 15,
	ipV6Address: 39,
	hostname: 250,
	groupName: 100,
	processActivityId: 50,
	processExecutionId: 50,
	processTaskName: 200,
	userAuthentication: 50,
	userAuthenticationToken: 20,
	tenantId: 50,
	tenantName: 100,
	error: 500,
	sessionId: 36,
	uuid: 36,
	aiFirstHumanMessage: 100,
	authProviderClientSecret: 40,
	authProviderUrl: 200,
	authAccessToken: 4000,
	authRefreshToken: 1500,
	authScope: 200,
}

export type CodeEditorSize = 'normal' | 'singleline' | 'large' | 'fill'

export type TsCodeType = string
export type JsCodeType = string
export type JsonType = string
export type VariableNameType = string
export type TsTypeType = string
export type HtmlType = string
export type ScssType = string
export type RoutePathType = string

export const PrimitiveTypeNames = ['', 'void', 'string', 'number', 'boolean', 'Date'] as const
export type PrimitiveType = typeof PrimitiveTypeNames[number]
export const TypeIcons: Record<PrimitiveType | BusinessObjectTypeType, string> = {
	'': '[-]',
	void: '[-]',
	string: '[abc]',
	number: '[#]',
	boolean: '[B]',
	Date: '[D]',
	DataStore: '[DS]',
	Entity: '[E]',
	StaticEntity: '[SE]',
	Screen: '[S]',
	Process: '[P]',
	ConfigurationItem: '[CI]',
	Label: '[L]',
	Module: '[M]',
	Role: '[R]',
	Permission: '[Per]',
	DataType: '[DT]',
	ServerDataStore: '[SDS]',
	ServiceInterface: '[SI]',
	Resource: '[R]',
	Language: '[LNG]',
	AccessControl: '[AC]',
	Trigger: '[TR]',
	Theme: '[T]',
	UserDefinedConfiguration: '[UDC]',
}

export interface DeclarationsContainer {
	declarations: string[]
	doEncloseInNamespace: boolean
	imports: Record<string, string> // identifier (can be '* as Alias') -> moduleName
}

export type NonFunctionPropertyNames<T> = { [K in keyof T]: T[K] extends Function ? never : K }[keyof T];
export type DeepReadonly<T> =
T extends any[] ? DeepReadonlyArray<T[number]> :
T extends object ? DeepReadonlyObject<T> :
T;

export interface DeepReadonlyArray<T> extends ReadonlyArray<DeepReadonly<T>> {}

export type DeepReadonlyObject<T> = {
	// readonly [P in NonFunctionPropertyNames<T>]: T[P] extends Date ? Date : DeepReadonly<T[P]>;
	readonly [P in keyof T]: T[P] extends Function ? T[P] : DeepReadonly<T[P]>;
};

export type NominalType<RealType, Marker> = RealType & { __marker?: Marker }
export interface ScreenDefinition<P, I> {
	moduleId: string
	boId: string
	component: Class
	fullUrl: string
	__pathParams?: P
	__Inputs?: I
}
export interface HasId {
	id: string | number
}
export type IdType<T extends HasId = HasId> = T['id']
export interface HasType {
	readonly __type: string
}
export interface HasBaseType {
	readonly __baseType: string
}
export interface HasBoType {
	readonly __boType: string
}

// export interface EntityClass extends HasId, HasType {
// }

export const PrimitiveConstructorNames = {
	'number': 'Number',
	'string': 'String',
	'boolean': 'Boolean',
	'Date': 'Date',
} as const

export const PrimitiveConstructors = {
	'number': Number,
	'string': String,
	'boolean': Boolean,
	'Date': Date,
} as const

// See DataSourceOption['type']
export const DbTypeNames = {
	postgres: 'PostgreSQL',
	mssql: 'MS SQL',
	mariadb: 'MariaDB',
	mysql: 'MySql',
	sqlite: 'SqLite',
	oracle: 'Oracle',
} as const
export type DbTechnology = keyof typeof DbTypeNames
export const DbStandardTypeList = ['numericPrimaryKey', 'number', 'string', 'Date', 'boolean', 'blob', 'clob', 'char', 'ArrayBuffer', 'Buffer', 'double', 'json'] as const
export type DbStandardTypes = {
	[K in typeof DbStandardTypeList[number]]: K extends 'numericPrimaryKey' ? ColumnType | 'serial' : ColumnType
}
export const DbTypes: Record<DbTechnology, DbStandardTypes> = {
	postgres: {
		numericPrimaryKey: 'serial',
		number: 'int',
		string: 'varchar',
		Date: 'timestamp',
		boolean: 'boolean',
		blob: 'bytea',
		clob: 'text',
		char: 'char',
		ArrayBuffer: 'bytea',
		Buffer: 'bytea',
		double: 'decimal',
		json: 'json',
	},
	sqlite: {
		numericPrimaryKey: 'int',
		number: 'int',
		string: 'varchar',
		Date: 'datetime',
		boolean: 'boolean',
		blob: 'blob',
		clob: 'text',
		char: 'character',
		ArrayBuffer: 'bytea',
		Buffer: 'bytea',
		double: 'double',
		json: 'simple-json',
	},
	mssql: {
		numericPrimaryKey: 'int',
		number: 'int',
		string: 'varchar',
		Date: 'datetime',
		boolean: 'boolean',
		blob: 'bytea',
		clob: 'clob',
		char: 'char',
		ArrayBuffer: 'bytea',
		Buffer: 'bytea',
		double: 'double',
		json: 'simple-json',
	},
	mariadb: {
		numericPrimaryKey: 'int',
		number: 'int',
		string: 'varchar',
		Date: 'datetime',
		boolean: 'boolean',
		blob: 'bytea',
		clob: 'clob',
		char: 'char',
		ArrayBuffer: 'bytea',
		Buffer: 'bytea',
		double: 'double',
		json: 'json',
	},
	mysql: {
		numericPrimaryKey: 'int',
		number: 'int',
		string: 'varchar',
		Date: 'datetime',
		boolean: 'boolean',
		blob: 'bytea',
		clob: 'clob',
		char: 'char',
		ArrayBuffer: 'bytea',
		Buffer: 'bytea',
		double: 'double',
		json: 'json',
	},
	oracle: {
		numericPrimaryKey: 'int',
		number: 'int',
		string: 'varchar',
		Date: 'datetime',
		boolean: 'boolean',
		blob: 'bytea',
		clob: 'clob',
		char: 'char',
		ArrayBuffer: 'bytea',
		Buffer: 'bytea',
		double: 'double',
		json: 'simple-json',
	},
} as const

export const BoReferencesFilters = ['cssResources', 'dbConnectionConfigItem', 'mailConnectionConfigItem'] as const
export type BoReferencesFilter = typeof BoReferencesFilters[number]

export type WrapperType = [string, string]

export interface ExecutionControllerExecutionResponse {
	data?: any
	error?: any
	stackTrace?: string[]
	logMessages?: [ClientLogLevel, any[]][]
	timeMs: number
}

export type WatcherEventName = 'add' | 'addDir' | 'change' | 'unlink' | 'unlinkDir'

export interface MainScreenClass {
	$canBeMainScreen: true
}
export interface DialogScreenClass {
	$canBeDialogScreen: true
}
export interface NameAndType {
	name: string
	type: string
}

export interface EventType {
	name: string,
	prefix?: string,
	parameters?: Parameter[]
}

export type RecordOrFunction<K extends string | number | symbol, V> = Record<K, V> | ((input: K) => V)

export const DirectionOptions = {
	vertical: 'vertical',
	horizontal: 'horizontal',
} as const
export const GridLayoutDirectionOptions = {
	...DirectionOptions,
	inherit: 'inherit',
} as const

export const FlexLayoutDirectionOptions = {
	vertical: 'vertical in one column',
	horizontal: 'horizontal in one row',
	'grid-horizontal': 'grid with fixed columns',
	'grid-vertical': 'grid with fixed rows',
	'block': 'normal <div> block',
	// columns: 'fixed number of columns',
	'vertical-reverse': 'vertical (bottom-up)',
	'horizontal-reverse': 'horizontal (right-to-left)',
	inherit: 'inherit',
} as const


export type Direction = keyof typeof DirectionOptions
export type GridLayoutDirection = keyof typeof GridLayoutDirectionOptions
export type FlexLayoutDirection = keyof typeof FlexLayoutDirectionOptions


export const BranchNameHeaderName = 'lowgile-branchname' // must be lower-case
export const LanguageHeaderName = 'lowgile-language' // must be lower-case

export type OnlyData<T> = {
	[P in keyof T as T[P] extends Class<any> ? P
	 	: T[P] extends Function ? never
		: P extends '__type' ? never
		: P
	] : T[P]
}

export type PartialData<T> = Partial<OnlyData<T>>

export type DeepRequired<T> = {
	[P in keyof T]-?: T[P] extends object ? DeepRequired<T[P]> : T[P]
}
export type UnwrapReadonly<T> = 
T extends readonly (infer U)[] ? U[] :
T extends ReadonlyArray<infer U> ? U[] :
T extends Readonly<infer U> ? U :
T

export interface DbQueryStructure {
	sql: string
	parameters: any[]
}

export type Class<T = any, A extends any[] = any[]> = new (...args: A) => T;
export type AbstractClass<T = any, A extends any[] = any[]> = abstract new (...args: A) => T;
export type ModuleCode = Record<string, Class>

export type Interface<C extends Class<InstanceType<C>> | AbstractClass<InstanceType<C>>, SI, I = {}> = 
    C extends Class<InstanceType<C>> 
    // ConcreteClass
    ? InstanceType<C> extends I 
        ? C extends (SI & Class<InstanceType<C>>)
            ? (InstanceType<C> & I)
            : (SI & Class<InstanceType<C>>) // Indicate StaticInterface Error
        : I // Indicate Interface Error
    // AbstractClass
    : InstanceType<C> extends I 
        ? C extends (SI & AbstractClass<InstanceType<C>>)
            ? (InstanceType<C> & I)
            : (SI & AbstractClass<InstanceType<C>>) // Indicate StaticInterface Error
        : I // Indicate Interface Error

export const BoTypeSymbol = Symbol.for('LowgileBoType')
export const EntityInfoSymbol = Symbol.for('LowgileEntityInfo')
export interface IsBo<T extends BusinessObjectTypeType = BusinessObjectTypeType> {
	readonly [BoTypeSymbol]: T
}

export interface EntityInfo {
	readonly idType: string
	readonly propertyNames: readonly string[]
	readonly propertyTypes: Readonly<Record<string, string>>
	readonly searchInProperties: readonly string[]
	readonly subsetNames: readonly string[]
	readonly manyToOneRelationships: Readonly<Record<string, string>> // property name of relation -> BO qualified name
	readonly oneToManyRelationships: Readonly<Record<string, string>> // property name of relation -> BO qualified name
	readonly oneToOneOwningRelationships: Readonly<Record<string, string>> // property name of relation -> BO qualified name
	readonly oneToOneSecondaryRelationships: Readonly<Record<string, string>> // property name of relation -> BO qualified name
}

export interface CallableBo<T extends BusinessObjectTypeType = BusinessObjectTypeType> extends IsBo<T> {
	readonly __type: string
}

export interface OnAfterCodeLoad extends Class {
	θafterCodeLoad(): Promise<void>
}

export interface EntityCommon extends CallableBo<'Entity'> {
	readonly __baseType: string
	readonly [EntityInfoSymbol]: EntityInfo
}
export interface EntityClass<T=any> extends EntityCommon {
	new (init?: Partial<T>): T
}
export interface EntityClassOnDb<T=any> extends EntityClass<T & HasId> {
}
export interface EntityInstance extends EntityCommon {
}

export interface EntityInstanceOnDb extends EntityInstance {
	id: string | number
}

export type EntityRecord<T extends EntityCommon> = {
	[P in T[typeof EntityInfoSymbol]['propertyNames'][number]]: P extends keyof T ? T[P] : any
}

export type SavedIds<T extends EntityInstanceOnDb> = {
	[P in 'id' | keyof T[typeof EntityInfoSymbol]['oneToManyRelationships'] | keyof T[typeof EntityInfoSymbol]['manyToOneRelationships']]:
		P extends 'id' ? T['id'] :
		P extends keyof T[typeof EntityInfoSymbol]['oneToManyRelationships'] ? (number | string)[] :
		(number | string)
}

export interface ServerDataStoreClass extends CallableBo<'ServerDataStore'> {
	// TODO: reactivate typed methods; this was deactivates as the PROD Angular compiler complained
	// loadById<T extends EntityInstanceOnDb>(type: Class<T>, id: T['id']): Promise<T>
	// loadByCondition<T extends EntityInstanceOnDb>(type: Class<T>, findOptions?: FindManyOptions<T>): Promise<T[]>
	// loadByQuery<T extends EntityInstanceOnDb>(type: Class<T>, query: DataQuery<T>, findOptions?: FindManyOptions<T>): Promise<T[]>
	// loadPageByQuery<T extends EntityInstanceOnDb>(type: Class<T>, paging: PagingDefinition, query: DataQuery<T>, findOptions?: Omit<FindManyOptions<T>, 'take' | 'skip'>): Promise<PagedData<T>>
	loadById<T=any>(type: Class<any>, id: any): Promise<any>
	loadByCondition<T=any>(type: Class<any>, findOptions?: FindManyOptions<any>): Promise<any[]>
	loadByQuery<T=any>(type: Class<any>, query: DataQuery<any>, findOptions?: FindManyOptions<any>): Promise<any[]>
	loadPageByQuery<T=any>(type: Class<any>, paging: PagingDefinition, query: DataQuery<any>, findOptions?: Omit<FindManyOptions<any>, 'take' | 'skip'>): Promise<PagedData<any>>
}

export interface StaticEntityCommon extends CallableBo<'StaticEntity'> {}
export interface StaticEntityClass extends StaticEntityCommon {}
export interface StaticEntityInstance extends StaticEntityCommon {}

export interface DataQuery<T extends EntityInstance> {
	query: string
	searchInProperties?: T[typeof EntityInfoSymbol]['propertyNames'][number][]
	caseSensitive?: boolean
}

export interface UserDefinedConfigurationClass<T=any> extends CallableBo<'UserDefinedConfiguration'> {
	get(): Promise<T>
	set(value: T): Promise<void>
}

export interface LanguageInfo {
	shortName: string
	displayName: string
	isoCode: string
}

export type ValidationError = {
	error: string
	skipSubsequentRules?: boolean
	ruleId?: string
}

export type ValidationRule<T> = (value: T, $scope: any) => Promise<ValidationError | undefined>
/** @deprecated */
export type ThreadType = 'main' | 'frontendCompiler' | 'backendCompiler' | 'requestWorker' | 'event' | 'static'
export const ServiceTypes = ['core', 'event', 'frontend-compiler', 'backend-compiler', 'app-api', 'app-api-executor', 'static'] as const
export const ThreadRoles = ['standalone', ...ServiceTypes, 'init'] as const
export type ServiceType = typeof ServiceTypes[number]
export type ClusterMode = 'standalone' | 'cluster'
export type ThreadRole = typeof ThreadRoles[number]

export interface DropdownOption {
	id: string | number
	label: string
	isOther?: boolean
}

export const ScreenQualifiedNameAttribute = 'data-defined-in'

export const ScreenSizes = [
	'XSmall',
	'Small',
	'Medium',
	'Large',
	'XLarge',
] as const // do NOT reorder, LayoutService depends on it!
export type ScreenSize = typeof ScreenSizes[number]

export type BreakpointSection = Record<ScreenSize, boolean>

export interface Breakpoints {
	sizeEquals: BreakpointSection
	sizeIsGreaterThan: BreakpointSection
	sizeIsGreaterThanOrEqual: BreakpointSection
	sizeIsLessThan: BreakpointSection
	sizeIsLessThanOrEqual: BreakpointSection
	isHandset: boolean
	isTablet: boolean
	isWeb: boolean
	isHandsetPortrait: boolean
	isTabletPortrait: boolean
	isWebPortrait: boolean
	isHandsetLandscape: boolean
	isTabletLandscape: boolean
	isWebLandscape: boolean
	isPortrait: boolean
	isLandscape: boolean
	screenSize: typeof ScreenSizes[number]
}


export const ipcPort = 1500

export type CrudOperation = 'read' | 'create' | 'update' | 'delete'
export type ClientOrServer = 'client' | 'server'

export interface MenuEntry {
	label: string
	onClick?: () => void
	children?: MenuEntry[]
}

export interface TableViewConfiguration<T=any> {
	columns?: (keyof T & string)[]
	sortColumn?: keyof T & string
	sortDirection?: 'asc' | 'desc'
	filter?: string
}

export interface TableViewConfigurations<T=any> {
	defaultView: string
	views: Record<string, TableViewConfiguration<T>>
}

export const LogLevels = ['critical', 'error', 'warning', 'info', 'debug', 'status'] as const
export type LogLevel = typeof LogLevels[number]

export type LockRequestResponse = {
	successful: false
	message: string
} | {
	successful: true
	lock: Lock
}

export const SessionCookieName = 'Lowgile_session'
export const CsrfHeaderName = 'x-lowgile-csrf'

type Key = string | number | symbol
type NestedRecord<K, V> = K extends Key ? Record<K, V> : never
export type Nested<K1 extends Key, K2, K3=never, K4=never, K5=never, K6=never> =
	[K3] extends [never] ? Record<K1, K2> :
	[K4] extends [never] ? Record<K1, NestedRecord<K2, K3>> :
	[K5] extends [never] ? Record<K1, NestedRecord<K2, NestedRecord<K3, K4>>> :
	[K6] extends [never] ? Record<K1, NestedRecord<K2, NestedRecord<K3, NestedRecord<K4, K5>>>> :
	Record<K1, NestedRecord<K2, NestedRecord<K3, NestedRecord<K4, NestedRecord<K5, K6>>>>>

export const LaneAssigneeTypes = {
	group: 'Group',
	role: 'Role',
	user: 'Specific user (not encouraged)',
} as const
export type LaneAssigneeType = keyof typeof LaneAssigneeTypes

export interface HasTokenAssignment {
	allowedUserIds: UserIdType[]
	allowedGroups: string[]
	allowedRoles: string[]
}

export const PagingSchema = z.strictObject({
	pageSize: z.number(),
	pageIndex: z.number(),
})
export const SortingSchema = z.strictObject({
	by: z.string(),
	direction: z.enum(['asc', 'desc']).optional(),
})
export const AllTokenFilterSchema = z.strictObject({
	moduleIds: z.union([z.string().array(), z.literal('*')]).optional()
})
export type AllTokenFilter = z.infer<typeof AllTokenFilterSchema>
export const UserRelatedTokenFilterSchema = AllTokenFilterSchema.extend({
	userId: UserIdSchema.optional(),
	includeAssigned: z.oboolean(),
	includeUserAllowed: z.oboolean(),
	includeRoleAllowed: z.oboolean(),
	includeGroupAllowed: z.oboolean(),
	moduleIds: z.union([z.string().array(), z.literal('*')]).optional()
	// includePreviouslyClaimed: z.boolean(),
})
export type UserRelatedTokenFilter = z.infer<typeof UserRelatedTokenFilterSchema>

export const LocalStorageRefreshTokenProperty = 'Lowgile_refresh_token';
export const LocalStorageUserProperty = 'Lowgile_user';
export type LoginMethod = 'credentials' | 'sso'

export interface ProcessActivityInfo {
	screenRef: string
	outputs: string[]
	outgoingFlows: ProcessOutgoingFlowInfo[]
	swimlaneName: string
}

export interface ProcessOutgoingFlowInfo {
	flowId: string
	flowName: string
	nextUserTaskName: string
	isDefault: boolean
}

export * from '@shared/app-types'