import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { ScreenEditorInput } from '../decorators/screen-editor-input'
import { BoVisitor } from '@shared/bos/bo-visitor'
import { Block, TextBlock } from '..'
import { DataTableBlock } from './data-table-block'
import { ContentAreaBlock } from './content-area-block'
import { FormInputBlock } from '../form/form-input-block'
import { BoReference } from '@shared/bos/bo-reference'

export const DataTableColumnStickyOptions = {
	'': 'None',
	sticky: 'Stick to left',
	stickyEnd: 'Stick to right',
} as const
export type DataTableColumnStickyOption = keyof typeof DataTableColumnStickyOptions

const ColumnFilterTypes = {
	none: 'None',
	withClear: 'With "clear" option',
	withoutClear: 'Without "clear" option',
	custom: 'Custom (ContentArea "filter")',
}
type ColumnFilterType = keyof typeof ColumnFilterTypes

@jsonObject({ name: 'DataTableColumnBlock' })
export class DataTableColumnBlock extends Block {
	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'input',
		order: 1,
		label: 'Column ID',
		contentType: 'text',
		elementType: 'input',
		isMandatory: () => true,
	})
	columnId: string = ''

	@jsonMember
	@ScreenEditorInput<DataTableColumnBlock>({
		inputType: 'checkbox',
		order: 2,
		label: 'Is editable',
		// onInitializedOrChanged: (colBlocks, properties) => {
		// 	for(const colBlock of colBlocks) {
		// 		if(colBlock.isEditable) {
		// 			colBlock.cellSource = 'expression'
		// 		}
		// 	}
		// }
	})
	isEditable: boolean = false
		
	@jsonMember(String)
	@ScreenEditorInput<DataTableColumnBlock>({
		inputType: 'code',
		order: 3,
		label: 'Header text',
		codeLanguage: 'ts',
		editorSize: 'singleline',
		isBinding: false,
		tsReturnType: () => 'string | number',
		isMandatory: () => true,
		alternativeContentAreaName: 'header',
	})
	headerTextCode: string = `'Column header'`

	@jsonMember(String)
	@ScreenEditorInput<DataTableColumnBlock>({
		inputType: 'code',
		order: 4,
		label: 'Cell text',
		codeLanguage: 'ts',
		editorSize: 'singleline',
		isBinding: false,
		tsReturnType: () => 'string | number',
		isMandatory: () => true,
		alternativeContentAreaName: 'cell',
	})
	cellTextCode: string = `'Cell content'`

	@jsonMember(String)
	@ScreenEditorInput<DataTableColumnBlock>({
		inputType: 'dropdown',
		order: 20,
		label: 'Stickiness',
		options: Object.entries(DataTableColumnStickyOptions),
	})
	sticky: DataTableColumnStickyOption = ''

	@jsonMember(String)
	@ScreenEditorInput<DataTableColumnBlock>({
		inputType: 'dropdown',
		order: 20,
		label: 'Column filter type',
		options: Object.entries(ColumnFilterTypes),
	})
	columnFilterType: ColumnFilterType = 'none'

	@jsonMember
	@ScreenEditorInput<DataTableColumnBlock>({
		inputType: 'checkbox',
		order: 6,
		tab: 'Advanced',
		label: 'Is sortable',
	})
	isSortable: boolean = false

	@jsonMember
	@ScreenEditorInput<DataTableColumnBlock>({
		inputType: 'boRef',
		order: 7,
		tab: 'Advanced',
		label: 'Use StaticEntity for filtering',
		allowedBoTypes: ['StaticEntity'],
		includeImportedModules: true,
		isVisible: (blocks) => blocks[0].columnFilterType != 'none',
	})
	filterStaticEntityRef: BoReference = new BoReference()

	@jsonMember(String)
	@ScreenEditorInput<DataTableColumnBlock>({
		inputType: 'code',
		order: 8,
		tab: 'Advanced',
		label: 'Sort/filter value',
		tooltip: () => ({ text: 'Optional alternative value to use for sorting and filtering' }),
		codeLanguage: 'ts',
		editorSize: 'singleline',
		isBinding: false,
		isVisible: blocks => {
			const tableBlock = blocks[0].getFirstParent(b => b instanceof DataTableBlock) as DataTableBlock
			if(!tableBlock) return false
			if(tableBlock.dataSource.sourceType != 'array') return false
			return true
		},
		tsReturnType: () => 'string | number | Date',
	})
	sortFilterValueCode: string = ``

	constructor(init: Partial<DataTableColumnBlock> = {}) {
		super()
		this.init(init)
	}

	static createNewForEditor(): Block {
		return new this({
			headerTextCode: `'Header'`,
			cellTextCode: `'Cell content'`,
		})
	}

	getColumnIdOrDefault() {
		return this.columnId ?? `column_${this.id}`
	}

	getEditorTitle() {
		let headerCode = this.headerTextCode
		const textBlock = this.getHeaderTextBlock()
		if(textBlock) headerCode = textBlock.textCode

		const [_, text] = (headerCode ?? '').match(/^\s*['"]([^'"]+)['"]\s*$/) ?? []
		if(text) {
			return `Column "${text}"`
		} else {
			return `Column id=${this.columnId ?? ''}`
		}

		// return `${super.getEditorTitle()} ${this.columnId ?? ''}`
	}

	canHaveParent(parent: Block): boolean {
		return parent instanceof DataTableBlock
	}

	getHeaderTextBlock() {
		const contentArea = this.getContentArea('header')
		const textBlock = contentArea?.children.find(b => b instanceof TextBlock) as TextBlock
		return textBlock
	}

	protected visitThisWithBoVisitor(visitor: BoVisitor, pathPrefix: (string | number)[]): void {
	}

	getEditorCategory(): string {
		return 'Tables & Lists'
	}

	get allowedStylePrefixes() {
		return ['header', 'filterCell']
	}

	getEffectiveSortAndFilterCode() {
		if(this.columnFilterType == 'none' && !this.isSortable) return ''

		if(this.sortFilterValueCode) return this.sortFilterValueCode
		const cellArea = this.getContentArea('cell')
		if(cellArea) {
			let firstFormInputBlock: FormInputBlock | undefined
			cellArea.visit(block => {
				if(!firstFormInputBlock && block instanceof FormInputBlock) {
					firstFormInputBlock = block as FormInputBlock
				}
			}, this, 0, 'first')
			if(firstFormInputBlock) {
				return firstFormInputBlock.bindingCode
			}

			let firstTextBlock: TextBlock | undefined
			cellArea.visit(block => {
				if(!firstTextBlock && block instanceof TextBlock) {
					firstTextBlock = block as TextBlock
				}
			}, this, 0, 'first')
			if(firstTextBlock) {
				return firstTextBlock.textCode
			}

			return ''
		} else {
			return this.cellTextCode
		}
	}

	public getOwnVariablesForChildContentArea(contentArea: ContentAreaBlock): Record<string, string | null> {
		if(contentArea.name == 'filter') {
			const table = this.getFirstParent(b => b instanceof DataTableBlock) as DataTableBlock
			const variables = {
				$table: 'any',
				[table.dataSource.loopVariableDefinition]: null
			}
			if(table.dataSource.indexVariableDefinition) {
				variables[table.dataSource.indexVariableDefinition] = null
			}
			return variables
		}
		return {}
	}
}
