import { MatDialog } from '@angular/material/dialog';
import { BranchNameType, BusinessObjectItem } from '@shared/types';
import { Screen } from '@shared/bos';
import { Observable, Subscription, of } from 'rxjs';
import { Component, OnInit, Input, OnDestroy, OnChanges, NgZone, QueryList, ViewChildren, AfterViewInit, ContentChildren, TemplateRef, HostListener, signal, ViewChild } from '@angular/core';
import { EditorPropertiesManager } from '@shared/blocks/decorators/editor-properties-manager';
import { Block, TextBlock } from '@shared/blocks';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import type { ScreenEditorComponent } from '../screen-editor/screen-editor.component'
import { BoReference } from '@shared/bos/bo-reference'
import { ActivatedRoute } from '@angular/router'
import { EditorHelperService } from '../editor-helpers/editor-helper.service'
import { EditorCodeProperty, EditorDropdownProperty, EditorPropertyWithKey, EditorInputProperty, EditorTsCodeProperty, EditorWizardData, EditorWizardDialog, EditorBoRefSubtypeProperty, EditorCompositionProperty, EditorBoRefProperty } from '@shared/blocks/decorators/editor-property-types'
import { map, shareReplay, tap } from 'rxjs/operators'
import { openDialogAndGetPromise } from '../../util/ui-util'
import type { ProcessEditorComponent } from '../process-editor/process-editor.component'
import { BusinessObject } from '@shared/bos/business-object'
import { DynamicTabDirective } from '@ng-shared/lib/directives/dynamic-tab.directive'
import { FieldsetPropertyBlock, GapPropertyBlock, PropertyBlock, PropertyPropertyBlock } from './property-blocks'
import { ContentAreaBlock } from '@shared/blocks/layout/content-area-block'
import { StudioTrpcService } from '@ng-shared/lib/services/studio-trpc.service'
import { MatTabGroup } from '@angular/material/tabs'
import { Semaphore } from '@shared/util/semaphore'

@Component({
  selector: 'app-properties',
  templateUrl: './properties.component.html',
  styleUrls: ['./properties.component.scss'],
})
export class PropertiesComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() items: BusinessObjectItem[];
  @Input() branchName: BranchNameType
  @Input() bo: BusinessObject;
  @Input() typeDeclarations: string = ''
  @Input() editor: ScreenEditorComponent | ProcessEditorComponent
  propertiesForm: FormGroup;
  formSubscription: Subscription;
  properties: EditorPropertyWithKey<BusinessObject, any>[] = []
  effectiveProperties: EditorPropertyWithKey<BusinessObject, any>[] = []
  tabNames = new Set<string>()
  tabPropertyBlocks: Record<string, PropertyBlock[]> = {}
  wizardDialogs: EditorWizardDialog[] = []
  areValuesMatching = new Map<string, boolean>()
  @ContentChildren(DynamicTabDirective) additionalTabs!: QueryList<DynamicTabDirective>
  @ViewChild(MatTabGroup, { static: true }) tabGroup: MatTabGroup

  boRefs$: Record<string, Observable<BoReference[]>> = {}
  boRefSubtypes$: Record<string, Observable<[string, string][]>> = {}
  bs = signal<Block[]>([])
  private propertiesUpdateSemaphore = new Semaphore()

  constructor(
    private trpcService: StudioTrpcService,
    private activatedRoute: ActivatedRoute,
    private helperService: EditorHelperService,
    private dialog: MatDialog,
    private ngZone: NgZone,
  ) {}

  async ngOnInit() {
    this.propertiesForm = null
    await this.updatePropertiesForm()
    for(const property of this.effectiveProperties) {
      property.onInitializedOrChanged?.(this.items, this.effectiveProperties, this.bo)
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.tabGroup.selectedIndex = 0)
  }
  
  async updatePropertiesForm() {
    const lock = await this.propertiesUpdateSemaphore.obtainLock()
    try {

      if (this.formSubscription) this.formSubscription.unsubscribe();
  
      const item0 = this.items[0];
      const propertyControls: Record<string | number | symbol, FormControl> = {};
  
      const itemProperties = this.items.map(item => EditorPropertiesManager.getAllProperties(item))
  
      this.properties = []
      for(const property of itemProperties[0]) {
        const propertyForAllItems = itemProperties.map(props => props.find(prop => prop.key == property.key))
        if(propertyForAllItems.every(Boolean)) {
          // all items have this property; therefore show for editing; otherwise omit
          this.properties.push(property)
        } else {
          continue
        }
      }
  
      this.wizardDialogs = this.helperService.getWizardDialogs(item0?.__type)
      const helper = this.helperService.getHelper(item0?.__type || item0?.type)
      await helper?.modifyEditorProperties(item0, this.properties, this.propertiesForm?.value ?? {})
      this.updateDisplayedProperties()
  
      for (const property of this.effectiveProperties) {
        let value = property.getter(item0, property.key)
        
        propertyControls[property.key] = new FormControl(value)
        if(property.isReadonly?.(this.items, this.effectiveProperties, this.bo)) {
          propertyControls[property.key].disable()
        }
  
        if(property.inputType == 'boRef') {
          this.boRefs$[property.key] = this.trpcService.queryAsObservable(
            client => client.bo.getBoReferences.query({
              branchName: this.activatedRoute.snapshot.params.branchName,
              moduleId: this.activatedRoute.snapshot.params.moduleId,
              boTypes: property.allowedBoTypes,
              includeImportedModules: property.includeImportedModules,
            })
          ).pipe(
            map(boRefs => boRefs.map(boRef => new BoReference(boRef))),
            shareReplay(),
          )
        }
  
        if(property.inputType == 'boRefSubtype') {
          const boRef = (property as EditorBoRefSubtypeProperty<any, any>).boRef(this.items, this.effectiveProperties, this.bo)
  
          if(boRef) {
            this.boRefSubtypes$[property.key] = this.trpcService.queryAsObservable(
              client => client.bo.getBoSpecificDetails.query({
                branchName: this.activatedRoute.snapshot.params.branchName,
                ...boRef,
            })).pipe(
              map(details => Object.entries(details.subTypes ?? {})),
              shareReplay(),
            )
          } else {
            this.boRefSubtypes$[property.key] = of([])
          }
        }
      }
      
      this.propertiesForm = new FormGroup(propertyControls);
      this.formSubscription = this.propertiesForm.valueChanges.subscribe(() => {
        const formValues = this.propertiesForm.getRawValue() // do NOT use formValues provided by valueChanges event as this would not include values for disabled controls
  
        for (const property of this.effectiveProperties) {
          const oldValue = property.getter(item0, property.key)
          const value = Reflect.get(formValues, property.key)
  
          if(value === oldValue) continue
          for(const item of this.items) {
            property.setter(item, property.key, value)
          }
        }
      })
    } finally {
      lock.release()
    }
  }

  async onPropertyChanged(property: EditorPropertyWithKey<BusinessObject, any>, updateForm: boolean) {
    for(const block of this.items) {
      const helper = this.helperService.getHelper(block.__type)
      await helper?.onPropertyChanged(block, property, this.effectiveProperties)
    }

    property.onChanged?.(this.items, this.effectiveProperties, this.bo)
    property.onInitializedOrChanged?.(this.items, this.effectiveProperties, this.bo)
    if(updateForm) {
      await this.updatePropertiesForm()
    }
  }

  getCodeContext$(property: EditorPropertyWithKey<BusinessObject, any>) {
    const additionalVariables = property.additionalVariables?.(this.items, this.effectiveProperties, this.bo)
    const additionalLines = property.additionalLines?.(this.items, this.effectiveProperties, this.bo)
    return this.editor.getCodeContext$('editorProperty', property, null, additionalVariables, additionalLines)
  }

  ngOnChanges(changes) {
    this.ngOnInit();
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe();
  }

  stopPropagation(event: Event) {
    event.stopPropagation()
  }

  matchBoRefs(ref1: BoReference, ref2: BoReference) {
    return ref1?.matches(ref2) ?? false
  }

  async openWizardDialog(event: MouseEvent, wizard: EditorWizardDialog) {
    event.preventDefault()
    if(wizard.getDialogClass) {
      console.log('blocks', this.items)
      const block = await openDialogAndGetPromise<Block, EditorWizardData<Screen, Block>>(this.dialog, wizard.getDialogClass(), { data: {
        branchName: this.branchName,
        bo: this.bo as Screen,
        item: this.items[0] as Block
      } })
      if(block) {
        const replaced = (this.editor as ScreenEditorComponent).bo.replaceBlock(this.items[0] as Block, block)
        this.items[0] = block

        // console.log('replaced', replaced, block)
      } else {
        this.updatePropertiesForm()
        // console.log('closed without replacement')
      }
    } else {
      console.error(`No dialog class defined for wizard ${wizard.label}`)
    }
  }

  asInputProperty(property: EditorPropertyWithKey<BusinessObject, any>) { return property as EditorInputProperty<Screen, Block> }
  asTsCodeProperty(property: EditorPropertyWithKey<BusinessObject, any>) { return property as EditorTsCodeProperty<Screen, Block> }
  asDropdownProperty(property: EditorPropertyWithKey<BusinessObject, any>) { return property as EditorDropdownProperty<Screen, Block> }
  asBoRefProperty(property: EditorPropertyWithKey<BusinessObject, any>) { return property as EditorBoRefProperty<Screen, Block> }
  asBoRefSubtypeProperty(property: EditorPropertyWithKey<BusinessObject, any>) { return property as EditorBoRefSubtypeProperty<Screen, Block> }
  entriesOf<T extends object>(obj: T) {
    if(!obj) return []
    return Object.entries(obj)
  }

  getTsReturnType(property: EditorPropertyWithKey<BusinessObject, any>) {
    return this.asTsCodeProperty(property).tsReturnType?.(this.items as Block[], this.bo as Screen)
  }

  getMultiSelectionValue(property: EditorPropertyWithKey<BusinessObject, any>, which: 'first' | 'last') {
    const item = this.items.at(which == 'first' ? 0 : -1)
    return property.getter(item, property.key)
  }

  setMultiSelectionValue(property: EditorPropertyWithKey<BusinessObject, any>, which: 'first' | 'last') {
    const value = this.getMultiSelectionValue(property, which)
    for(const item of this.items) {
      property.setter(item, property.key, value)
    }
    this.areValuesMatching.set(property.key, true)
    this.propertiesForm.controls[property.key].setValue(value)
  }

  updateDisplayedProperties() {
    this.effectiveProperties = this.properties.flatMap(p => {
      const compositionObjFn = (p as EditorCompositionProperty<any, any>).compositionObj
      if(compositionObjFn) {
        const subProperties = EditorPropertiesManager.getAllProperties(compositionObjFn(this.items, this.effectiveProperties, this.bo)).map(subProperty => ({
          ...subProperty,
          key: `${p.key}.${subProperty.key}`,
          order: p.order + (subProperty.order / 10000),
          setter: (target: object, key:string, value: any) => {
            const subObj = target[p.key]
            const subSetter = subProperty.setter
            subSetter(subObj, subProperty.key, value)
          },
          getter: (target, key) => {
            const subObj = target[p.key]
            const subGetter = subProperty.getter
            return subGetter(subObj, subProperty.key)
          },
        }))
        return subProperties
      } else {
        return [p]
      }
    })
    this.effectiveProperties.sort((a, b) => a.order - b.order)

    this.areValuesMatching = new Map()
    for(const property of this.effectiveProperties) {
      const itemValues = this.items.map(item => JSON.stringify(property.getter(item, property.key)))
      this.areValuesMatching.set(property.key, new Set(itemValues).size == 1)
    }

    
    const blocksToVisibleBlocks = (blocks: PropertyBlock[]): PropertyBlock[] => {
      return blocks.flatMap(block => {
        if(block.type == 'gap') return []
        if(block.type == 'fieldset') {
          return [{
            ...block,
            propertyBlocks: blocksToVisibleBlocks(block.propertyBlocks)
          } as PropertyBlock]
        }

        const p = block.property
        const isVisible = !p.isVisible || p.isVisible(this.items, this.effectiveProperties, this.bo)
        if(isVisible) {
          return [block]
        }
    
        // if gap should be shown instead of completely hiding, add a placeholder for the gap
        const leaveGapIfHidden = p.leaveGapIfHidden?.(this.items, this.effectiveProperties, this.bo)
        if(leaveGapIfHidden) {
          return [{
            type: 'gap',
          } as PropertyBlock]
        }
    
        // otherwise do not add a property
        return []
      })

    }

    this.tabNames = new Set(this.effectiveProperties.map(p => p.tab))

    this.tabPropertyBlocks = Object.fromEntries([...this.tabNames].map(tab => {
      const tabProperties = this.effectiveProperties.filter(p => p.tab == tab)
      const fieldsets = new Map<string, FieldsetPropertyBlock>()
      const propertyBlocks: PropertyBlock[] = []

      for(const p of tabProperties) {
        if(p.fieldset) {
          const fieldsetName = p.fieldset(this.items, this.effectiveProperties, this.bo)
          let fieldset = fieldsets.get(fieldsetName)
          if(fieldset) {
            // fieldset already created & added; just add property to it but don't add a block to the main list
            fieldset.propertyBlocks.push({ type: 'property', property: p } as PropertyPropertyBlock)
          } else {
            // create fieldset with current property and add it to list
            fieldset = { type: 'fieldset', propertyBlocks: [{ type: 'property', property: p } as PropertyPropertyBlock], legend: fieldsetName }
            fieldsets.set(fieldsetName, fieldset)
            propertyBlocks.push(fieldset)
          }
        } else {
          propertyBlocks.push({ type: 'property', property: p } as PropertyPropertyBlock)
        }
      }

      const visibleTabBlocks = blocksToVisibleBlocks(propertyBlocks)
      
      return [tab, visibleTabBlocks]
    }))

    this.tabNames = new Set([...this.tabNames].filter(tab => this.tabPropertyBlocks[tab]?.length))
  }

  getLabel(property: EditorPropertyWithKey<BusinessObject, any>) {
    if(typeof property.label == 'function') {
      return property.label(this.items, this.effectiveProperties, this.bo)
    }
    return property.label
  }

  asPropertyBlock(block: unknown) {
    return block as PropertyBlock
  }

  asPropertyPropertyBlock(block: unknown) {
    return block as PropertyPropertyBlock
  }

  asFieldsetPropertyBlock(block: unknown) {
    return block as FieldsetPropertyBlock
  }

  isBlock(item: BusinessObjectItem): item is Block {
    return item instanceof Block
  }

  addContentAreaForProperty(property: EditorTsCodeProperty<Screen, Block> & EditorPropertyWithKey<Screen, Block>) {
    if(!confirm(`Move property "${property.label}" into ContentArea "${property.alternativeContentAreaName}"?`)) return
    
    for(const block of this.items as Block[]) {
      let code: string
      code = property.getter(block, property.key)
      property.setter(block, property.key, '')
  
      let contentArea = block.getContentArea(property.alternativeContentAreaName)
      if(!contentArea) {
        contentArea = new ContentAreaBlock({ name: property.alternativeContentAreaName })
        block.children.splice(0, 0, contentArea)
      }
  
      let textBlock = contentArea.children.find(child => child instanceof TextBlock) as TextBlock
      if(!textBlock) {
        textBlock = new TextBlock()
        contentArea.children.splice(0, 0, textBlock)
      }
      textBlock.textCode = code
    }

    this.updatePropertiesForm()
  }

  removeContentAreaForProperty(property: EditorTsCodeProperty<Screen, Block> & EditorPropertyWithKey<Screen, Block>) {
    if(!confirm(`Move ContentArea "${property.alternativeContentAreaName}" into property "${property.label}"?`)) return

    for(const block of this.items as Block[]) {
      let contentArea = block.getContentArea(property.alternativeContentAreaName)

      if(contentArea) {
        const textBlock = contentArea.children.find(b => b instanceof TextBlock) as TextBlock
        const code = textBlock?.textCode || ''
        property.setter(block, property.key, code)

        block.children = block.children.filter(b => b !== contentArea)
      }
    }

    this.updatePropertiesForm()
  }

  @HostListener('cut', ['$event'])
  @HostListener('copy', ['$event'])
  @HostListener('paste', ['$event'])
  preventBubbling(event: Event) {
    // stop propagation as otherwise cut, copy and paste events would be fired on the selected blocks
    if(document.activeElement !== document.body) {
      event.stopPropagation()
    }
  }
}
