import { FormInputBlock } from './form-input-block';
import type { EventType, HtmlType } from './../../types'
import type { TsCodeType } from '../../types'
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { ScreenEditorInput } from '../decorators/screen-editor-input'

export const InputBlockTypeNames = {
	text: 'Text',
	number: 'Number',
	textArea: 'Text Area'
} as const
export type InputBlockType = keyof typeof InputBlockTypeNames

@jsonObject({ name: 'InputBlock' })
export class InputBlock extends FormInputBlock {
	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'code',
		order: 1,
		label: 'Label',
		codeLanguage: 'ts',
		tsReturnType: () => 'string',
		editorSize: 'singleline',
		isBinding: false,
	})
	labelCode?: TsCodeType = ''

	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'code',
		order: 2,
		label: 'Binding',
		codeLanguage: 'ts',
		tsReturnType: () => 'string | number',
		editorSize: 'singleline',
		isBinding: true,
		isMandatory: () => true,
	})
	bindingCode?: TsCodeType = ''
	
	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'dropdown',
		order: 3,
		label: 'Type',
		options: Object.entries(InputBlockTypeNames),
		isBinding: false
	})
	type: InputBlockType = 'text'

	@jsonMember(Boolean)
	@ScreenEditorInput({
		inputType: 'checkbox',
		order: 4,
		label: 'Show clear button',
		isBinding: false
	})
	showClearButton: boolean = false

	constructor(init: Partial<InputBlock> = {}) {
		super()
		this.exampleText = 'Some input'
		this.init(init)
	}

	get allowedEventTypes(): EventType[] {
		return [
			{ name: 'keydown' },
			{ name: 'keyup' },
			{ name: 'change' },
		]
	}

	producePreview(): HtmlType {
		return `<input tabindex="-1" value="${this.calculateExampleText()}">`
	}

	getEditorCategory(): string {
		return 'Core'
	}
}
