import { Block, TextBlock } from '@shared/blocks';
import type { TsCodeType, EventType } from '../../types'
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { ScreenEditorInput } from '../decorators/screen-editor-input'
import { BoReference } from '@shared/bos/bo-reference'

export const LinkTargetTypes = {
	screen: 'Screen',
	url: 'URL',
} as const

export const LinkOpenIns = {
	_self: 'In same frame (default, "_self")',
	_blank: 'In new window/tab ("_blank")',
	_top: 'Replace whole window ("_top")',
	_parent: 'Replace parent frame ("_parent")',
	named: 'In explicitly named window',
} as const

@jsonObject({ name: 'LinkBlock' })
export class LinkBlock extends Block {
	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'dropdown',
		order: 1,
		label: 'Target type',
		options: Object.entries(LinkTargetTypes),
	})
	targetType: keyof typeof LinkTargetTypes = 'screen'

	@jsonMember
	@ScreenEditorInput({
		inputType: 'boRef',
		order: 2,
		label: 'Screen',
		allowedBoTypes: ['Screen'],
		includeImportedModules: true,
		isVisible: (items: LinkBlock[]) => items[0].targetType == 'screen',
		isMandatory: () => true,
	})
	screenRef: BoReference = new BoReference()

	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'code',
		order: 3,
		label: 'Screen parameters',
		codeLanguage: 'ts',
		tsReturnType: () => 'Record<string, number | string>',
		editorSize: 'singleline',
		isBinding: false,
		isVisible: (items: LinkBlock[]) => items[0].targetType == 'screen',
		isMandatory: () => true,
	})
	screenParamsCode: TsCodeType = '{}'

	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'code',
		order: 2,
		label: 'URL',
		codeLanguage: 'ts',
		tsReturnType: () => 'string',
		editorSize: 'singleline',
		isBinding: false,
		isVisible: (items: LinkBlock[]) => items[0].targetType == 'url',
		isMandatory: () => true,
	})
	url?: TsCodeType

	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'dropdown',
		order: 3,
		label: 'Open in',
		options: Object.entries(LinkOpenIns),
	})
	openIn: keyof typeof LinkOpenIns = '_self'

	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'code',
		order: 2,
		label: 'Open in window name',
		codeLanguage: 'ts',
		tsReturnType: () => 'string',
		editorSize: 'singleline',
		isBinding: false,
		isVisible: (items: LinkBlock[]) => items[0].openIn == 'named',
		isMandatory: () => true,
	})
	openInWindowNameCode: TsCodeType = ''

	@jsonMember
	@ScreenEditorInput({
		inputType: 'checkbox',
		order: 3,
		label: 'Browser should download',
		isBinding: false
	})
	shouldDownload: boolean = false

	@jsonMember(String)
	@ScreenEditorInput<LinkBlock>({
		inputType: 'code',
		order: 4,
		label: 'Download file name',
		codeLanguage: 'ts',
		tsReturnType: () => 'string',
		editorSize: 'singleline',
		isBinding: false,
		isVisible: items => items[0].shouldDownload,
		leaveGapIfHidden: () => true,
		isMandatory: () => true,
	})
	downloadFileNameCode: TsCodeType = ''


	constructor(init: Partial<LinkBlock> = {}) {
		super()
		this.init(init)
	}

	static createNewForEditor(): Block {
		return new this({
			children: [
				new TextBlock({ textCode: `'Text'`})
			]
		})
	}

	get allowedEventTypes(): EventType[] { return [
		{ name: 'click' },
		{ name: 'dblclick' },
	]}

	getPreviewDirection(): 'row' | 'column' {
		return 'row'
	}

	getEditorCategory(): string {
		return 'Core'
	}
}
