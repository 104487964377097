<div class="top">
	<div class="video-and-image">
		<video #video class="hidden" (click)="takePicture()"></video>
		<img #image class="hidden" />
	</div>
	<div class="actions">
		<input #file type="file" class="hidden" (change)="onFileSelected($event)" />
		@if(isCapturing() || hasTakenPicture) {
			<button (click)="cancelCapture()">Cancel</button>
		}
		@if(!isCapturing()) {
			@if(hasTakenPicture) {
				<button (click)="startCapturing()">Retake</button>
				<button (click)="usePicture()">Use this picture</button>
			} @else {
				<button (click)="startCapturing()">Take a picture</button>
			}
		}
		<button (click)="selectFile()">Select a file</button>
	</div>
</div>
