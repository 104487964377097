import { TextBlock } from '@shared/blocks/other/text-block';
import { LinkBlock } from '@shared/blocks/other/link-block';
import { Block } from '../block'
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { ListBlock } from './list-block'
import { BlockStyle } from '../styles/block-style'

@jsonObject({ name: 'MainLayoutSidebarBlock' })
export class MainLayoutSidebarBlock extends Block {
	constructor(init: Partial<MainLayoutSidebarBlock> = {}) {
		super()
		this.init(init)
	}

	static createNewForEditor(): Block {
		return new this({ 
			styles: [
				new BlockStyle({ name: 'width', value: '15em' })
			],
			children: [
				new ListBlock({
					kind: 'navigation',
					children: [
						new LinkBlock({
							children: [
								new TextBlock({
									textCode: `'Link'`
								})
							]
						})
					]
				})
			]
		})
	}

	getTitleBackgroundAndBorderColor(): string {
		return 'black'
	}

	getTitleColor(): string {
		return 'white'
	}

	getEditorCategory(): string {
		return 'Screen Structure'
	}
}
