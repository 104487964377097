import { ConfigItemSelectionOption, ConfigurationItemProperty } from '@shared/bos/configuration-item-property'
import { AiTypes, DbTechnology, DbTypeNames } from '@shared/types'

export interface ConfigType {
	name: string
	properties: ConfigurationItemProperty[]
}

const credentialProperties = [
	new ConfigurationItemProperty({ name: 'userName', type: 'string', defaultExpression: `''`, isSecret: false, isAvailableOnClient: false }),
	new ConfigurationItemProperty({ name: 'password', type: 'string', defaultExpression: `''`, isSecret: true, isAvailableOnClient: false }),
]
const connectionProperties = [
	new ConfigurationItemProperty({ name: 'location', type: 'string', defaultExpression: `''`, isSecret: false, isAvailableOnClient: false, hint: 'Hostname / filename / URL, depending on the use of this configuration' }),
	new ConfigurationItemProperty({ name: 'port', type: 'number', defaultExpression: `0`, isSecret: false, isAvailableOnClient: false, validationRegex: '^\\d+$', hint: 'Use 0 to choose default port' }),
]
const connectionWithCredentialProperties = [
	...connectionProperties,
	...credentialProperties,
]
const dbConnectionProperties = [
	new ConfigurationItemProperty({ name: 'dbType', type: 'string', defaultExpression: `'postgres'`, isSecret: false, isAvailableOnClient: false, inputType: 'dropdown',
		selectionOptions: Object.keys(DbTypeNames).map(key => new ConfigItemSelectionOption({ value: key, displayName: DbTypeNames[key as keyof typeof DbTypeNames] }))
	}),
	new ConfigurationItemProperty({ name: 'dbName', type: 'string', defaultExpression: `''`, isSecret: false, isAvailableOnClient: false, hint: 'Name of the DB within the DB server' }),
	new ConfigurationItemProperty({ name: 'dbSchema', type: 'string', defaultExpression: `''`, isSecret: false, isAvailableOnClient: false, hint: 'Schema within the DB' }),
	...connectionProperties,
]
const aiConnectionProperties = [
	new ConfigurationItemProperty({ name: 'aiType', type: 'Sys.$Types.AiType', isAvailableOnClient: false, inputType: 'dropdown', selectionOptions: AiTypes.map(aiType => new ConfigItemSelectionOption({
		value: aiType,
		displayName: aiType
	}))}),
	new ConfigurationItemProperty({ name: 'instanceName', type: 'string', isAvailableOnClient: false, defaultExpression: `''` }),
	new ConfigurationItemProperty({ name: 'deploymentName', type: 'string', isAvailableOnClient: false, defaultExpression: `''` }),
	new ConfigurationItemProperty({ name: 'embeddingsDeploymentName', type: 'string', isAvailableOnClient: false, defaultExpression: `''` }),
	new ConfigurationItemProperty({ name: 'apiKey', type: 'string', isAvailableOnClient: false, isSecret: true, defaultExpression: `''` }),
	new ConfigurationItemProperty({ name: 'apiVersion', type: 'string', isAvailableOnClient: false, defaultExpression: `''` }),
	new ConfigurationItemProperty({ name: 'usdPer1000InputTokens', type: 'number | undefined', isAvailableOnClient: false, defaultExpression: `undefined` }),
	new ConfigurationItemProperty({ name: 'usdPer1000OutputTokens', type: 'number | undefined', isAvailableOnClient: false, defaultExpression: `undefined` }),
]

export type ConfigTypeName = 'blank' | 'credential' | 'connection' | 'connectionWithCredential' | 'dbConnection' | 'aiConnection'
type ConfigTypes = Record<ConfigTypeName, ConfigType>
export const ConfigTypes: ConfigTypes = {
	blank: { name: 'Blank', properties: [] },
	credential: { name: 'Credential', properties: credentialProperties },
	connection: { name: 'Connection (anonymous)', properties: connectionProperties },
	connectionWithCredential: { name: 'Connection (with credential)', properties: connectionWithCredentialProperties },
	dbConnection: { name: 'DB connection', properties: dbConnectionProperties },
	aiConnection: { name: 'AI connection', properties: aiConnectionProperties },
}

export interface CredentialSetting {
	userName: string
	password: string
}
export interface ConnectionSetting {
	location: string
	port: number
}
export interface ConnectionSettingWithCredential extends ConnectionSetting, CredentialSetting {}

export interface DbConnectionSetting extends ConnectionSettingWithCredential {
	dbType: DbTechnology
	dbName: string
	dbSchema: string
}