<mat-tab-group
	class="top"
	fxFill
	animationDuration="200ms"
	mat-stretch-tabs="false" mat-align-tabs="start"
>
	<mat-tab *ngFor="let tabName of tabNames" [label]="tabName || 'Properties'">
		<div *ngIf="propertiesForm" [formGroup]="propertiesForm" class="properties-grid">
			<div class="property" *ngFor="let wizard of wizardDialogs">
				<a href="#" (click)="openWizardDialog($event, wizard)">
					{{ wizard.label }}
				</a>
			</div>
		
			<ng-container *ngFor="let block of tabPropertyBlocks[tabName]">
				<ng-container *ngTemplateOutlet="asPropertyBlock(block).type == 'fieldset' ? fieldsetTemplate : propertyTemplate; context: { $implicit: block }"></ng-container>
			</ng-container>

			<ng-template #fieldsetTemplate let-block>
				<fieldset class="properties-fieldset" *ngIf="asFieldsetPropertyBlock(block) as block">
					<legend>{{ block.legend }}</legend>
					<ng-container *ngFor="let child of block.propertyBlocks">
						<ng-container *ngTemplateOutlet="propertyTemplate; context: { $implicit: child }"></ng-container>
					</ng-container>
				</fieldset>
			</ng-template>

			<ng-template #gapTemplate>
				<div class="property"></div>
			</ng-template>

			<ng-template #propertyTemplate let-block>
				<div class="property" *ngIf="asPropertyPropertyBlock(block)?.property as property; else gapTemplate" [style.gridColumn]="property.spanColumns || 'auto'">
					<div class="property__name" [style.color]="block.property.obsolete ? 'red' : ''">
						<label [attr.for]="property.key">{{ getLabel(property) }}</label>
						<lowgile-help *ngIf="property.tooltip && property?.tooltip(items, properties, bo) as tooltip" vPosition="above">
							<div *ngIf="tooltip.acceptHtml; else nonHtmlTooltip" [innerHtml]="tooltip.text | safeHtml"></div>
							<ng-template #nonHtmlTooltip>
								{{ tooltip.text }}
							</ng-template>
						</lowgile-help>
						<span class="mandatoryAsterisk" [ngSwitch]="property.isMandatory && property.isMandatory(items, properties, bo)">
						<span *ngSwitchCase="true">*</span>
						<span *ngSwitchCase="'(*)'"><sup>(</sup>*<sup>)</sup></span>
						</span>
						<div *ngIf="asTsCodeProperty(property)?.alternativeContentAreaName && isBlock(items[0]) && !items[0].getContentArea(asTsCodeProperty(property)?.alternativeContentAreaName)">
							<a class="add-content-area" href="#" (click)="$event.preventDefault(); addContentAreaForProperty(asTsCodeProperty(property))">(move to Content Area)</a>
						</div>
					</div>
					<div *ngIf="areValuesMatching.get(property.key); else valuesNotMatching" class="property__input">
						<div [ngSwitch]="property.inputType">
							<div
							*ngSwitchCase="'input'"
							[ngSwitch]="asInputProperty(property).elementType"
							>
							<input
								*ngSwitchCase="'input'"
								[formControl]="$any(propertiesForm.controls[property.key])"
								[id]="property.key"
								[type]="asInputProperty(property).contentType"
								(keydown.delete)="stopPropagation($event)"
								(ngModelChange)="onPropertyChanged(property, false)"
							/>
					
							<textarea
								*ngSwitchCase="'textarea'"
								[formControl]="$any(propertiesForm.controls[property.key])"
								[id]="property.key"
								(keydown.delete)="stopPropagation($event)"
								(ngModelChange)="onPropertyChanged(property, false)"
							></textarea>
							</div>
					
							<div
							*ngSwitchCase="'code'"
							class="code-editor"
							[class.singleLine]="asTsCodeProperty(property).editorSize == 'singleline'"
							>
							<div *ngIf="isBlock(items[0]) && items[0].getContentArea(asTsCodeProperty(property).alternativeContentAreaName); else editorTemplate">
								(is contained in Content Area "{{ asTsCodeProperty(property).alternativeContentAreaName }}") <a href="#" (click)="$event.preventDefault(); removeContentAreaForProperty(asTsCodeProperty(property))">[remove Content Area]</a>
							</div>
							<ng-template #editorTemplate>
								<code-editor-new
									[formControl]="$any(propertiesForm.controls[property.key])"
									[id]="property.key"
									[size]="asTsCodeProperty(property).editorSize"
									[typeDeclarations]="typeDeclarations"
									[context]="getCodeContext$(property) | async"
									(ngModelChange)="onPropertyChanged(property, false)"
								></code-editor-new>
								Returns {{ getTsReturnType(property) }}
							</ng-template>
							</div>
					
							<select
								*ngSwitchCase="'dropdown'"
								[formControl]="$any(propertiesForm.controls[property.key])"
								[id]="property.key"
								(ngModelChange)="onPropertyChanged(property, true)"
							>
								<option *ngIf="asDropdownProperty(property).noneOptionName" [value]="asDropdownProperty(property).noneOptionValue">
									<em>(none)</em>
								</option>
								<option *ngFor="let option of asDropdownProperty(property).options" [value]="option[0]">{{
									option[1]
								}}</option>
							</select>
					
							<input
								*ngSwitchCase="'checkbox'"
								type="checkbox"
								[formControl]="$any(propertiesForm.controls[property.key])"
								(ngModelChange)="onPropertyChanged(property, true)"
							>
					
							<select
								*ngSwitchCase="'boRef'"
								[formControl]="$any(propertiesForm.controls[property.key])"
								[id]="property.key"
								(ngModelChange)="onPropertyChanged(property, true)"
								[compareWith]="matchBoRefs"
							>
								<option *ngIf="asBoRefProperty(property).noneOptionName" [value]="null">
									<em>{{ asBoRefProperty(property).noneOptionName }}</em>
								</option>
								<option *ngFor="let option of boRefs$[property.key] | async" [ngValue]="option">{{
									option.getQualifiedName()
								}}</option>
							</select>
					
							<select
								*ngSwitchCase="'boRefSubtype'"
								[formControl]="$any(propertiesForm.controls[property.key])"
								[id]="property.key"
								(ngModelChange)="onPropertyChanged(property, true)"
							>
								<option *ngIf="asBoRefSubtypeProperty(property).noneOptionName" [value]="undefined">
									<em>{{ asBoRefSubtypeProperty(property).noneOptionName }}</em>
								</option>
								<option *ngFor="let option of boRefSubtypes$[property.key] | async" [ngValue]="option[0]">{{
									option[1]
								}}</option>
							</select>
					
							<lowgile-type-reference
								*ngSwitchCase="'typeDropdown'"
								[formControl]="$any(propertiesForm.controls[property.key])"
								(ngModelChange)="onPropertyChanged(property, true)"
							></lowgile-type-reference>
						</div>
					</div>
					<ng-template #valuesNotMatching>
						<div class="flexRow" style="gap: 1em;">
							<em>(Values are not matching)</em>
							<a href="#" (click)="setMultiSelectionValue(property, 'first'); $event.preventDefault()">Use first selection's value</a>
							<lowgile-help>{{ getMultiSelectionValue(property, 'first') }}</lowgile-help>
							<a href="#" (click)="setMultiSelectionValue(property, 'last'); $event.preventDefault()">Use last selection's value</a>
							<lowgile-help>{{ getMultiSelectionValue(property, 'last') }}</lowgile-help>
						</div>
					</ng-template>
				</div>
			</ng-template>
		</div>
	</mat-tab>

	<mat-tab *ngFor="let tab of additionalTabs" [label]="tab.label">
		<ng-container [ngTemplateOutlet]="tab.templateRef"></ng-container>
	</mat-tab>
</mat-tab-group>
