import { HasVariableScope } from './../bos/has-variable-scope';
import { DataUtil } from './../util/data-util';
import { JsonMappable } from './../data/json-mappable'

import type { HtmlType, TsCodeType, Class, EventType, BusinessObjectItem } from '../types'
import { jsonObject, jsonMember, jsonArrayMember, toJson, jsonMapMember } from 'typedjson'
import { EventHandler } from './events/event-handler'
import { ScreenEditorInput } from './decorators/screen-editor-input'
import { BoVisitor } from '@shared/bos/bo-visitor'
import { EditorPropertiesManager } from './decorators/editor-properties-manager'
import { BoReference } from '@shared/bos/bo-reference'
import { Parameter } from '@shared/script/parameter'
import type { LogicBlock } from './logic/logic-block'
import type { LayoutBlock } from './layout/layout-block'
import { BlockMenuEntry } from './block-menu-entry'
import type { IconBlockFontSet } from './other/icon-block'
import type { ContentAreaBlock } from './layout/content-area-block'
import { BlockStyle } from './styles/block-style'

export type IconPreviewType = { type: 'icon', fontIcon: string, fontSet: IconBlockFontSet }
export type PreviewType = HtmlType | IconPreviewType

export const BlockAppearances = {
	fill: 'Filled (with background)',
	outline: 'Outlined',
} as const
export type BlockAppearance = keyof typeof BlockAppearances
export const BlockColors = {
	'': 'Basic',
	primary: 'Primary',
	accent: 'Accent',
	warn: 'Warn',
} as const
export type BlockColor = keyof typeof BlockColors

@jsonObject
@toJson
export class Block implements JsonMappable, HasVariableScope, BusinessObjectItem {
	static readonly listInEditor: boolean = true

	@jsonMember(String) 
	get __type(): string { 
		return DataUtil.getJsonTypeName(this, 'Block')
	}
	set __type(_) { /*ignore*/ }

	@jsonMember(Number) id: number = 0
	@jsonArrayMember(() => Block) children: Block[] = []
	@jsonArrayMember(String) classes: string[] = []
	
	@ScreenEditorInput({
		inputType: 'input',
		order: 1001,
		label: 'Flex',
		contentType: 'text',
		elementType: 'input',
	})
	@jsonMember(String) flex?: string

	@ScreenEditorInput({
		inputType: 'checkbox',
		order: 1002,
		label: 'Fill parent',
	})
	@jsonMember(Boolean) fillParent: boolean = false
	
	@ScreenEditorInput({
		inputType: 'code',
		order: 1001,
		label: blocks => {
			const nlp = blocks[0].getNonLogicParent()
			return `Span across ${nlp.isLayoutBlock() && nlp.layoutDirection == 'grid-horizontal' ? 'column' : 'row'} width`
		},
		codeLanguage: 'ts',
		tsReturnType: () => 'number',
		editorSize: 'singleline',
		isBinding: false,
		isVisible(blocks, properties, bo) {
			const isLogicBlock = blocks[0].isLogicBlock()
			if(isLogicBlock) return false
			const nlp = blocks[0].getNonLogicParent()
			return nlp?.isLayoutBlock() && (nlp.layoutDirection == 'grid-horizontal' || nlp.layoutDirection == 'grid-vertical')
		},
	})
	@jsonMember(String) layoutSpanCode: string = "1"
	
	@ScreenEditorInput({
		inputType: 'code',
		order: 1001,
		label: blocks => {
			const nlp = blocks[0].getNonLogicParent()
			return `Place at ${nlp.isLayoutBlock() && nlp.layoutDirection == 'grid-horizontal' ? 'column' : 'row'} number (optional)`
		},
		codeLanguage: 'ts',
		tsReturnType: () => 'number',
		editorSize: 'singleline',
		isBinding: false,
		isVisible(blocks, properties, bo) {
			const isLogicBlock = blocks[0].isLogicBlock()
			if(isLogicBlock) return false
			const nlp = blocks[0].getNonLogicParent()
			return nlp?.isLayoutBlock() && (nlp.layoutDirection == 'grid-horizontal' || nlp.layoutDirection == 'grid-vertical')
		},
	})
	@jsonMember(String) layoutStartLineCode: string = ""
	
	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'dropdown',
		order: 100,
		label: 'Color',
		tab: 'Appearance',
		isVisible: (blocks) => blocks[0].canHaveColor(),
		options: Object.entries(BlockColors)
	})
	color: BlockColor = ''

	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'dropdown',
		order: 100,
		label: 'Appearance',
		tab: 'Appearance',
		isVisible: (blocks) => blocks[0].canHaveAppearance(),
		options: Object.entries(BlockAppearances)
	})
	appearance?: BlockAppearance = 'fill'


	@jsonArrayMember(BlockStyle)
	styles: BlockStyle[] = []
	@jsonArrayMember(EventHandler)
	eventHandlers: EventHandler[] = []

	parent?: Block
	readonly allowedChildTypes: '*' | Class<Block>[] = '*'

	get allowedEventTypes(): EventType[] { return [] }
	get allowedStylePrefixes(): string[] { return [] }

	static createNewForEditor(): Block {
		return new this()
	}

	getEditorCategory(): string | null {
		return 'Others'
	}

	init(init?: object) {
		DataUtil.assignCommonProperties(this, init)
	}

	getAllowedChildTypes(): string[] | undefined {
		return undefined
	}

	canHaveChildren(): boolean {
		return true
	}

	canHaveChild(child: Block): boolean {
		if (this.allowedChildTypes == '*') return true

		const included = this.allowedChildTypes.includes(
			<Class<Block>>child.constructor
		)
		return included
	}

	canHaveColor() {
		return false
	}

	canHaveAppearance() {
		return false
	}

	isLayoutBlock(): this is LayoutBlock {
		return false
	}

	isLogicBlock(): this is LogicBlock {
		return false
	}

	getBlockName() {
		return this.__type.replace('Block', '').replace(/(?<!^)([A-Z])/g, ' $1')
	}

	getEditorTitle() {
		return this.getBlockName()
	}

	getPreviewDirection(): 'row' | 'column' {
		return 'column'
	}

	getPreviewStyles(): string {
		return `display: flex; flex-direction: ${this.getPreviewDirection()}; flex-wrap: wrap;`
	}

	getTitleColor() {
		return 'black'
	}

	getTitleBackgroundAndBorderColor() {
		return 'lightgray'
	}

	producePreview(): PreviewType {
		return ''
	}

	canHaveParent(parent: Block | undefined): boolean {
		throw new Error("Shouldn't instantiate Block class directly")
	}

	needsBoSpecificDetailsForRefs(): BoReference[] {
		return []
	}

	protected transpileExpressionForHtml(tsCode: TsCodeType | undefined) {
		if (tsCode === undefined) return ''
		// TODO: get babel to work for transpiling backtick template literals
		return tsCode

		// const transpiled = transform(tsCode, {
		// 	plugins: ['@babel/plugin-transform-template-literals'],

		// })
		// return transpiled
	}

	protected getOwnVariables(includeDeclarationVariables: boolean): Record<string, string | null> {
		return {}
	}

	public getOwnVariablesForChildContentArea(contentArea: ContentAreaBlock): Record<string, string | null> {
		return {}
	}

	getVariableScope(includeDeclarationVariables: boolean): Record<string, string> {
		const parentScope = this.parent?.getVariableScope(includeDeclarationVariables)
		const ownScope = this.getOwnVariables(includeDeclarationVariables)
		const scope = { ...parentScope, ...ownScope}

		for(const [name, type] of Object.entries(scope)) {
			if(type === null) {
				// allow unsetting variable (e.g. if a specific child ContentArea can't use the parent's variables) by setting type === null
				delete scope[name]
			}
		}

		return scope as Record<string, string>
	}

	getVariableScopeAsParameters(includeDeclarationVariables: boolean) {
		const scope = this.getVariableScope(includeDeclarationVariables)
		const params = Object.entries(scope).map(([name, type]) => new Parameter({ name, type }))
		return params
	}

	calculateExampleText() {
		const obj = this as any
		const exampleText = obj.exampleText || obj.bindingCode
			?.replace(/.*\./g, '')
			?.replace(/((?<=[a-z0-9_])[A-Z])/g, ' $1')
			?.replace(/^[a-z]/, (char: string) => char.toUpperCase())
			|| this.getEditorTitle()

		return exampleText
	}

	visit(visitor: (block: Block, parent: Block | null, idxInParent: number) => void, parent: Block | null, idxInParent: number, children: 'first' | 'last' | 'ignore') {
		if(children == 'first') this.children.forEach((child, idx) => child.visit(visitor, this, idx, children))
		visitor(this, parent, idxInParent)
		if(children == 'last') this.children.forEach((child, idx) => child.visit(visitor, this, idx, children))
	}

	visitWithBoVisitor(visitor: BoVisitor, pathPrefix: (string | number)[]) {
		this.visitThisWithBoVisitor(visitor, pathPrefix)
		this.children.forEach((child, idx) => child.visitWithBoVisitor(visitor, [...pathPrefix, idx]))
	}

	protected visitThisWithBoVisitor(visitor: BoVisitor, pathPrefix: (string | number)[]) {}
	protected visitCodePropertyWithBoVisitor<T extends Block>(visitor: BoVisitor, pathPrefix: (string | number)[], blockClass: Class<T>, propertyKey: keyof T & string) {
		const property = EditorPropertiesManager.getPropertyByKey(blockClass.prototype, propertyKey)
		const blockAndProperty = { block: this, property }
		visitor.visitTsCode(
			Reflect.get(this, propertyKey) as string,
			blockAndProperty,
			'property',
			[...pathPrefix, propertyKey],
			newCode => Reflect.set(this, propertyKey, newCode)
		)
	}

	getBlockPath() {
		const indices: number[] = []
		for(let block: Block = this; block.parent; block = block.parent) {
			const idx = block.parent?.children?.indexOf(block)
			if(idx != null) indices.push(idx)
		}

		return indices.reverse()
	}

	getEditableBo(): BoReference | null {
		return null
	}

	replaceBlock(oldBlock: Block, newBlock: Block, recursive: boolean) {
		let success = false

		this.visit((block, parent, idxInParent) => {
			if(block === oldBlock) {
				if(parent?.children[idxInParent] === oldBlock) {
					parent.children[idxInParent] = newBlock
					success = true
				} else {
					console.error('Block could not be replaced; not found')
				}
			}
		}, null, 0, recursive ? 'first' : 'ignore')

		return success
	}

	getNonLogicParent() {
		return this.getFirstParent(block => !block.isLogicBlock())
	}

	getParentChain() {
		const parents: Block[] = []
		let block = this.parent
		while(block) {
			parents.push(block)
			block = block.parent
		}
		return parents
	}

	getFirstParent<T extends Block>(predicate: (block: Block) => block is T, startAtSelf?: boolean): T
	getFirstParent(predicate: (block: Block) => boolean, startAtSelf?: boolean): Block
	getFirstParent(predicate: (block: Block) => boolean, startAtSelf?: boolean) {
		let block = startAtSelf ? this : this.parent
		while(block) {
			if(predicate(block)) return block
			block = block.parent
		}
		return null
	}

	getMenuEntries(): BlockMenuEntry[] {
		return ([
			{ label: 'Cut', onClick(editor) { editor.cut() } },
			{ label: 'Copy', onClick(editor) { editor.copy() } },
			{ label: 'Paste after', onClick(editor) { editor.paste('after', this) } },
			{ label: 'Paste inside', onClick(editor) { editor.paste('inside', this) }, isDisabled() { return !this.canHaveChildren() } },
			'---',
			{ label: 'Delete', onClick(editor) { editor.delete() } },
			{ label: 'Delete & keep children', onClick(editor) { editor.deleteKeepChildren() }, isDisabled() { return !this.children.length } },
			'---',
			{ label: 'Wrap with If', onClick(editor) { editor.wrapWithIf() } },
			{ label: 'Wrap with Layout', onClick(editor) { editor.wrapWithLayout() } },
			'---',
			{ label: 'Move before parent', onClick(editor) { editor.moveNextToParent('before', this) }, isDisabled() { return !this.parent?.parent } },
			{ label: 'Move after parent', onClick(editor) { editor.moveNextToParent('after', this) }, isDisabled() { return !this.parent?.parent } },
			{ label: 'Move into previous sibling', onClick(editor) { editor.moveIntoSibling('previous', this) }, isDisabled() {
				if(!this.parent) return false
				const idxInParent = this.parent.children.findIndex(b => b === this)
				const sibling = this.parent.children[idxInParent - 1]
				return !sibling || !sibling.canHaveChildren()
			} },
			{ label: 'Move into next sibling', onClick(editor) { editor.moveIntoSibling('next', this) }, isDisabled() {
				
				if(!this.parent) return false
				const idxInParent = this.parent.children.findIndex(b => b === this)
				const sibling = this.parent.children[idxInParent + 1]
				return !sibling || !sibling.canHaveChildren()
			} },
		] as BlockMenuEntry[]).filter(Boolean)
	}

	getContentArea(name: string) {
		return this.children.find(child => child.__type == 'ContentAreaBlock' && (child as ContentAreaBlock).name == name)
	}

	shouldRenderContentAreaWrappers() {
		return false
	}
}
