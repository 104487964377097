import { FormInputBlock } from './form-input-block';
import type { HtmlType } from './../../types'
import type { TsCodeType } from '../../types'
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { LeafBlock } from '../leaf-block'
import { ScreenEditorInput } from '../decorators/screen-editor-input'

@jsonObject({ name: 'CheckboxBlock' })
export class CheckboxBlock extends FormInputBlock {
	@jsonMember(String)
	@ScreenEditorInput<CheckboxBlock>({
		inputType: 'code',
		order: 1,
		label: 'Text next to checkbox',
		codeLanguage: 'ts',
		tsReturnType: () => 'string',
		editorSize: 'singleline',
		isBinding: false,
	})
	textCode?: TsCodeType = ''

	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'code',
		order: 2,
		label: 'Binding',
		codeLanguage: 'ts',
		tsReturnType: () => 'boolean',
		editorSize: 'singleline',
		isBinding: true,
		isMandatory: () => true,
	})
	bindingCode?: TsCodeType = ''

	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'code',
		order: 5,
		label: 'Label',
		codeLanguage: 'ts',
		tsReturnType: () => 'string',
		editorSize: 'singleline',
		isBinding: false,
	})
	labelCode?: TsCodeType = ''

	constructor(init: Partial<CheckboxBlock> = {}) {
		super()
		this.init(init)
	}

	producePreview(): HtmlType {
		return `<input type="checkbox" tabindex="-1">${this.calculateExampleText()}`
	}

	getEditorCategory(): string {
		return 'Core'
	}
}
