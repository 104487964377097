import { FormInputBlock } from './form-input-block';
import type { HtmlType } from './../../types'
import type { TsCodeType } from '../../types'
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { ScreenEditorInput } from '../decorators/screen-editor-input'

@jsonObject({ name: 'StaticEntityBlock' })
export class StaticEntityBlock extends FormInputBlock {
	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'code',
		order: 1,
		label: 'Label',
		codeLanguage: 'ts',
		tsReturnType: () => 'string',
		editorSize: 'singleline',
		isBinding: false,
	})
	labelCode?: TsCodeType = ''

	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'code',
		order: 2,
		label: 'Binding',
		codeLanguage: 'ts',
		tsReturnType: () => 'string | number | Sys.Types.StaticEntityInstance',
		editorSize: 'singleline',
		isBinding: true,
		isMandatory: () => true,
	})
	bindingCode?: TsCodeType = ''

	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'code',
		order: 3,
		label: 'Options list',
		codeLanguage: 'ts',
		tsReturnType: () => 'Sys.HasDropdownOptions | Sys.DropdownOptions',
		editorSize: 'singleline',
		isBinding: false,
		isMandatory: () => true,
	})
	optionListCode?: TsCodeType

	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'code',
		order: 4,
		label: 'Option value',
		codeLanguage: 'ts',
		tsReturnType: () => 'string | number',
		editorSize: 'singleline',
		isBinding: false,
		isMandatory: () => true,
		additionalLines: (blocks: StaticEntityBlock[]) => [`const $options = (${blocks[0].optionListCode})`.replace(/\n/g, ' ')],
		additionalVariables: () => ({ $option: `typeof $options[number]`}),
		tooltip: () => ({
			text: 'Use <code>$option</code> to access each item of the options list',
			acceptHtml: true,
		}),
	})
	optionValueCode?: TsCodeType

	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'code',
		order: 5,
		label: 'Option label',
		codeLanguage: 'ts',
		tsReturnType: () => 'string | number',
		editorSize: 'singleline',
		isBinding: false,
		isMandatory: () => true,
		additionalLines: (blocks: StaticEntityBlock[]) => [`const $options = (${blocks[0].optionListCode})`.replace(/\n/g, ' ')],
		additionalVariables: () => ({ $option: `typeof $options[number]`}),
		tooltip: () => ({
			text: 'Use <code>$option</code> to access each item of the options list',
			acceptHtml: true,
		}),
	})
	optionLabelCode?: TsCodeType

	@ScreenEditorInput({
		inputType: 'checkbox',
		order: 10,
		label: 'Allow clearing value',
	})
	@jsonMember(Boolean) allowClearing: boolean = true
}
