import { ScreenUtil } from '../../util/screen-util'
import { LogicBlock } from './logic-block'
import type { TsCodeType } from '../../types'
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { Block } from '../block'
import { ScreenEditorInput } from '../decorators/screen-editor-input'
import { BoVisitor } from '@shared/bos/bo-visitor'
import { EditorPropertiesManager } from '../decorators/editor-properties-manager'

@jsonObject({ name: 'IfBlock' })
export class IfBlock extends LogicBlock {
	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'code',
		order: 1,
		label: 'Condition',
		codeLanguage: 'ts',
		tsReturnType: () => 'any',
		editorSize: 'normal',
		isBinding: false,
		isMandatory: () => true,
		spanColumns: '1 / -1',
	})
	conditionCode: TsCodeType = 'false'

	constructor(init: Partial<IfBlock> = {}) {
		super()
		this.init(init)
	}

	getEditorTitle() {
		return `${super.getEditorTitle()} ${this.conditionCode}`
	}

	visitThisWithBoVisitor(visitor: BoVisitor, pathPrefix: (string | number)[]): void {
		// visitor.visitTsCode(this.conditionCode, EditorPropertiesManager.getPropertyByKey(IfBlock.prototype, 'conditionCode'), 'property', [...pathPrefix, 'conditionCode'], newCode => this.conditionCode = newCode)
		this.visitCodePropertyWithBoVisitor(visitor, pathPrefix, IfBlock, 'conditionCode')
	}

	getEditorCategory(): string {
		return 'Logic'
	}
}
