import type { HtmlType } from './../../types'
import type { TsCodeType } from '../../types'
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { LeafBlock } from '../leaf-block'
import { ScreenEditorInput } from '../decorators/screen-editor-input'

export const TextBlockTypeNames = {
	text: 'Normal text',
	html: 'HTML',
	markdown: 'Markdown',
	handlebars: 'Handlebars Template',
} as const
export type TextBlockType = keyof typeof TextBlockTypeNames
export const TextBlockStyleNames = {
	p: 'Paragraphs (p)',
	span: 'Normal text (span)',
	h1: 'Heading 1 (h1)',
	h2: 'Heading 2 (h2)',
	h3: 'Heading 3 (h3)',
	h4: 'Heading 4 (h4)',
	h5: 'Heading 5 (h5)',
	h6: 'Heading 6 (h6)',
} as const
export type TextBlockStyle = keyof typeof TextBlockStyleNames
export const HtmlModes = {
	none: 'None',
	safe: 'Safe HTML only',
	unsafe: 'Unsafe HTML (DANGEROUS!)',
} as const
export type HtmlMode = keyof typeof HtmlModes

@jsonObject({ name: 'TextBlock' })
export class TextBlock extends LeafBlock {
	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'code',
		order: 1,
		label: 'Text',
		codeLanguage: 'ts',
		tsReturnType: () => 'string | number',
		editorSize: 'singleline',
		isBinding: false,
		isMandatory: () => true,
	})
	textCode: TsCodeType = ''

	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'dropdown',
		order: 2,
		label: 'Style',
		options: Object.entries(TextBlockStyleNames),
		isBinding: false
	})
	style: TextBlockStyle = 'span'

	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'dropdown',
		order: 10,
		label: 'Allow HTML (CAREFUL!)',
		tab: 'Advanced',
		options: Object.entries(HtmlModes),
		tooltip: () => ({ text: 'Only use UNSAFE HTML if you absolutely have to and <b>under no circumstance use UNSAFE HTML to display values that a user can influence! If you need to display user input together with UNSAFE HTML, first use Sys.Text.encodeHtml on the user input</b>', acceptHtml: true })
	})
	htmlMode: HtmlMode = 'none'

	constructor(init: Partial<TextBlock> = {}) {
		super()
		this.init(init)
	}

	producePreview(): HtmlType {
		if(this.exampleText) {
			return this.transpileExpressionForHtml(this.exampleText)
		}
		if(this.textCode) {
			return this.transpileExpressionForHtml(this.textCode)
		}
		return `<span class="example">${this.exampleText}</span>`
	}

	getEditorCategory(): string {
		return 'Core'
	}
}
