import type { HtmlType, EventType, TsCodeType } from './../../types'
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { LeafBlock } from '../leaf-block'
import { ScreenEditorInput } from '../decorators/screen-editor-input'
import { StepperBlock } from './stepper-block'

export const ButtonTypes = {
	'mat-button': 'Basic (without background / elevation / outline)',
	'mat-raised-button': 'Raised (with background & elevation)',
	'mat-stroked-button': 'Stroked (with outline)',
	'mat-flat-button': 'Flat (with background)',
	'mat-icon-button': 'Icon Large (circular; meant to contain icon)',
	'mat-icon-button mat-medium-icon-button': 'Icon Medium (circular; meant to contain icon)',
	'mat-icon-button mat-small-icon-button': 'Icon Small (circular; meant to contain icon)',
	'mat-icon-button mat-tiny-icon-button': 'Icon Tiny (circular; meant to contain icon)',
	'mat-fab': 'Large Floating Action Button (FAB)',
	'mat-mini-fab': 'Small Floating Action Button (Mini FAB)',
	'mat-fab extended': 'Floating Action Button (FAB) with text',
} as const
export type ButtonType = keyof typeof ButtonTypes

export const ButtonStepperFunctions = {
	'': '(none)',
	'matStepperNext': 'Next step',
	'matStepperPrevious': 'Previous step',
} as const
export type ButtonStepperFunction = keyof typeof ButtonStepperFunctions

@jsonObject({ name: 'ButtonBlock' })
export class ButtonBlock extends LeafBlock {
	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'code',
		order: 1,
		label: 'Text',
		codeLanguage: 'ts',
		tsReturnType: () => 'string',
		editorSize: 'singleline',
		isBinding: false,
		isMandatory: () => true,
		alternativeContentAreaName: 'content',
	})
	textCode?: TsCodeType

	@ScreenEditorInput({
		inputType: 'dropdown',
		order: 2,
		label: 'Stepper function',
		options: Object.entries(ButtonStepperFunctions),
		isVisible(blocks, properties, bo) {
			return !!blocks[0].getFirstParent(p => p instanceof StepperBlock)
		},
	})
	@jsonMember(String) stepperFunction: ButtonStepperFunction = ''


	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'dropdown',
		order: 101,
		label: 'Button appearance',
		tab: 'Appearance',
		options: Object.entries(ButtonTypes)
	})
	buttonType: ButtonType = 'mat-raised-button'

	constructor(init: Partial<ButtonBlock> = {}) {
		super()
		this.init(init)
	}

	get allowedEventTypes(): EventType[] { return [
		{ name: 'click' },
		{ name: 'dblclick' },
	]}

	canHaveColor(): boolean {
		return true
	}

	producePreview(): HtmlType {
		if(this.getContentArea('content')) return ''
		return `<button tabindex="-1">${this.exampleText || 'Button'}</button>`
	}

	getEditorCategory(): string {
		return 'Core'
	}
}
