import { Block } from '@shared/blocks'
import { EditorPropertyWithKey, EditorTsCodeProperty, ScreenEditorProperty } from '@shared/blocks/decorators/editor-property-types'
import { FormValidationRule } from '@shared/blocks/form/form-validation-rule'
import { Entity, Screen } from '@shared/bos'
import { AccessControl } from '@shared/bos/access-control'
import { BusinessObject } from '@shared/bos/business-object'
import { DataStore } from '@shared/bos/data-store'
import { EntityMethod, EntityMethodAvailabilities } from '@shared/bos/entity-method'
import { EntityProperty } from '@shared/bos/entity-property'
import { RestrictableItem } from '@shared/bos/restrictions/restrictable-item'
import { RestrictableItemRestriction } from '@shared/bos/restrictions/restrictable-item-restriction'
import { ServerDataStore } from '@shared/bos/server-data-store'
import { ServerDataStoreProcedure } from '@shared/bos/server-data-store-procedure'
import { ServerDataStoreView } from '@shared/bos/server-data-store-view'
import { StaticEntity, StaticEntitySources } from '@shared/bos/static-entity'
import { StaticEntityProperty } from '@shared/bos/static-entity-property'
import { TypeReference } from '@shared/data/type-reference'
import { StoreProperty } from '@shared/store/store'
import { ValidationManager } from '@shared/validation/validation-manager'
import { CodeContext, CodeContextOptions } from './code-context'
import { ExecutionContext, ExecutionContextName, ExecutionLocation } from '@shared/script/execution-context'
import { Parameter } from '@shared/script/parameter'
import { VariableDefinition } from '@shared/script/variable-definition'
import { LowgileAction } from '@shared/script/lowgile-action'
import { LazyCache } from '@shared/data/lazy-cache'
import { BusinessObjectItem, ProcessScope, ProcessTokenScope } from '@shared/types'
import { Process } from '@shared/bos/process'
import { Trigger } from '@shared/bos/trigger'

const executionContextCache = new LazyCache<'EC', ExecutionContextName, ExecutionContext>()

// GENERAL -----------------------------
export class TypeContext extends CodeContext {
	constructor(public options: CodeContextOptions, public mustExtend: string, public executionContextName: ExecutionContextName) {
		super(options)
		this.setPrePostfix()
	}

	setPrePostfix(): void {
		this.prefix = `${this.imports}\n`
			+ `function $dummy(_: ${this.mustExtend}) {}\n`
			+ `$dummy(null as any as`
		this.postfix = ')'
	}

	get executionContext(): ExecutionContext {
		return executionContextCache.get('EC', this.executionContextName, name => new ExecutionContext(name))
	}

	get prefixHiddenLineCount(): number { return 3 }
	get postfixHiddenLineCount(): number { return 1 }
}

abstract class BaseEditorBlockPropertyDefaultContext<T extends BusinessObject, Item extends BusinessObjectItem> extends CodeContext<T> {
	constructor(public options: CodeContextOptions<T>, public property: EditorPropertyWithKey<T, any>, public item: Item) {
		super(options)
		this.codeLanguage = (property as EditorTsCodeProperty<any, any>).codeLanguage || this.codeLanguage
		const tsReturnType = (property as EditorTsCodeProperty<any, any>).tsReturnType || this.tsReturnType 
		this.tsReturnType = tsReturnType instanceof Function ? tsReturnType([this.item], this.bo) : tsReturnType

		this.setPrePostfix()
	}

	getVariableScope(): Record<string, string> {
		return {}
	}

	setPrePostfix(): void {
		if(!this.property || !this.item) return // not yet set in super constructor

		const variableScope = this.getVariableScope()
	
		this.prefix = `${this.imports}\n`
			+ (this.additionalLines ?? []).map(line => `${line}\n`)
			+ `${this.getVariableDeclarationsLine(variableScope, this.property.isBinding)}\n`
			+ `(async function (this: never): Promise<DeepReadonly<${this.tsReturnType || 'any'}>> {`
		this.postfix = '})'
	}

	get prefixHiddenLineCount(): number { return 3 + (this.additionalLines?.length ?? 0) }
	get postfixHiddenLineCount(): number { return 1 }
}

export class ScreenEditorBlockPropertyDefaultContext extends BaseEditorBlockPropertyDefaultContext<Screen, Block> {
	getVariableScope(): Record<string, string> {
		return this.item.getVariableScope(true) || {}
	}
	
	setPrePostfix(): void {
		if(!this.property || !this.item) return // not yet set in super constructor

		super.setPrePostfix()
		this.prefix += ` return (`
		this.postfix = ')' + this.postfix
	}

	get executionContext(): ExecutionContext {
		return new ExecutionContext('screenDisplay')
	}
}

export class ProcessEditorBlockPropertyDefaultContext extends BaseEditorBlockPropertyDefaultContext<Process, any> {
	getVariableScope(): Record<string, string> {
		const variables: Partial<Record<keyof ProcessTokenScope, string>> = {
			process: `This.$Process.${this.bo.boId}.ProcessData`,
			initiator: 'Sys.Types.User | null',
		}

		if(this.property.key != 'assigneeExpression') {
			variables['token'] = `{ ${this.options.bo.tokenVariableDefinitions.map(vd => vd.getSignature(false, false)).join(', ')} }`
			variables['action'] = 'string'
			variables['swimlane'] = 'Sys.Types.Swimlane'
		}

		return variables
	}

	get executionContext(): ExecutionContext {
		return new ExecutionContext('server')
	}
}

export class ConsoleContext extends CodeContext {
	setPrePostfix(): void {
		this.prefix = `${this.imports}\n(async function () {`
		this.postfix = '})'
	}
	
	get executionContext(): ExecutionContext {
		return new ExecutionContext('server')
	}

	get prefixHiddenLineCount(): number { return 2 }
	get postfixHiddenLineCount(): number { return 1 }
}

export class CssContext extends CodeContext<BusinessObject> {
	setPrePostfix(): void {
		this.codeLanguage = 'scss'
	}
}

export class HtmlContext extends CodeContext<BusinessObject> {
	setPrePostfix(): void {
		this.codeLanguage = 'html'
	}
}

class GenericVariableDefaultContext<BO extends BusinessObject> extends CodeContext<BO> {
	protected _executionContext: ExecutionContext
	constructor(public options: CodeContextOptions<BO>, public variable: VariableDefinition, public variableDefinitions: VariableDefinition[], public additionalContextVariables: VariableDefinition[], executionContextName: ExecutionContextName) {
		super(options)
		this.variable = variable
		this._executionContext = new ExecutionContext(executionContextName)

		this.setPrePostfix()
	}

	setPrePostfix(): void {
		if(!this.variable) return // not yet set in super constructor

		const variableIdx = this.variableDefinitions?.findIndex(v => v === this.variable) ?? -1
		const previousVariableDeclarations = [
			...this.additionalContextVariables,
			...this.variableDefinitions.filter((_, idx) => idx < variableIdx)
		].map(def => `${def.name}: ${def.type}`)
		
		const thisDeclaration = `{${previousVariableDeclarations.join(', ')}}`
		
		this.prefix = `${this.imports}\n` +
			`(function (this: ${thisDeclaration}): ${this.variable.type} { return (`
		this.postfix = ')})'
	}

	get executionContext(): ExecutionContext {
		return this._executionContext
	}

	get prefixHiddenLineCount(): number { return 2 }
	get postfixHiddenLineCount(): number { return 1 }
}


// ACCESSCONTROL -----------------------------
export class AccessControlRestrictionContext extends CodeContext<AccessControl> {
	constructor(public options: CodeContextOptions<AccessControl>, public restriction: RestrictableItemRestriction, public restrictable: RestrictableItem) {
		super(options)
		this.setPrePostfix()
	}

	setPrePostfix(): void {
		if(!this.restriction || !this.restrictable) return // not yet set in super constructor

		const params = this.restrictable?.parameters.map(p => p.getSignature(false)).join(', ') ?? ''
		const generics = this.restrictable?.generics.length ? `<${this.restrictable.generics.join(', ')}>` : ''

		this.prefix = `${this.imports}\n`
			+ Object.entries(this.restrictable.contextImports).map(e => `import { ${e[0]} } from '${e[1]}'`).join('; ') + '\n'
			+ this.restrictable.contextDeclarations.map(decl => `type ${decl.name} = ${decl.type}`).join('; ') + '\n'
			+ `async function ${this.restrictable.functionName || this.restriction.itemName}${generics}(${params}): Promise<void> {`
		this.postfix = '}'
	}

	get executionContext(): ExecutionContext {
		return new ExecutionContext('server')
	}

	get prefixHiddenLineCount(): number { return 3 }
	get prefixDisabledLineCount(): number { return 4 }
	get postfixDisabledLineCount(): number { return 1 }
}


// DATASTORE -----------------------------
export class DataStorePropertyContext extends CodeContext<DataStore> {
	constructor(public options: CodeContextOptions<DataStore>, public property: StoreProperty) {
		super(options)
		this.setPrePostfix()
	}

	setPrePostfix(): void {
		if(!this.property) return

		const type = new TypeReference(this.property.type)
		type.isArray ||= this.property.isMultiple
		
		this.prefix = `${this.imports}\n`
			+ `(async function (this: never): Promise<${type.getQualifiedTypeName()}> { return (`,
		this.postfix = ')})'
	}

	get executionContext(): ExecutionContext {
		return new ExecutionContext('storeAction')
	}

	get prefixHiddenLineCount(): number { return 2 }
	get postfixHiddenLineCount(): number { return 1 }
}


// ENTITY -----------------------------
export class EntityMethodContext extends CodeContext<Entity> {
	constructor(public options: CodeContextOptions<Entity>, public method: EntityMethod) {
		super(options)
		this.setPrePostfix()
	}

	setPrePostfix(): void {
		if(!this.method) return // not yet set in super constructor

		const executionContext = this.executionContext
		const thisType = executionContext.isReadOnly() ? `DeepReadonly<${this.bo.getQualifiedName()}>` : this.bo.getQualifiedName()
		
		this.prefix = `${this.imports}\n` +
			`${this.method.getSignature(executionContext, false, thisType, true)} {`
		this.postfix = '}'
	}

	get executionContext(): ExecutionContext {
		const contextName = EntityMethodAvailabilities.find(av => av.id == this.method.availability)?.executionLocation!
		return new ExecutionContext(contextName)
	}

	get prefixHiddenLineCount(): number { return 1 }
	get prefixDisabledLineCount(): number { return 2 }
	get postfixDisabledLineCount(): number { return 1 }
}

export class PropertyDefaultContext extends CodeContext<BusinessObject> {
	constructor(public options: CodeContextOptions<Entity>, public property: { type: string }) {
		super(options)
		this.property = property

		this.setPrePostfix()
	}

	setPrePostfix(): void {
		if(!this.property) return // not yet set in super constructor

		const executionContext = this.executionContext
		const thisType = executionContext.isReadOnly() ? `DeepReadonly<${this.bo.getQualifiedName()}>` : this.bo.getQualifiedName()
		
		this.prefix = `${this.imports}\n` +
			`(function (this: never): ${this.property.type} { return (`
		this.postfix = ')})'
	}

	get executionContext(): ExecutionContext {
		return new ExecutionContext('calculation')
	}

	get prefixHiddenLineCount(): number { return 2 }
	get postfixHiddenLineCount(): number { return 1 }
}


// SCREEN -----------------------------
export class ScreenActionContext extends CodeContext<Screen> {
	constructor(public options: CodeContextOptions<Screen>, public action: LowgileAction) {
		super(options)
		this.action = action

		this.setPrePostfix()
	}

	setPrePostfix(): void {
		if(!this.action) return // not yet set in super constructor

		const thisActionsDef = this.options.bo.actions.map(a => `action_${a.name}: (${a.inputs.map(i => `${i.name}: ${i.type}, `).join('')}$context: Sys.Types.ScreenActionContext) => Promise<void>`).join(', ')
		const params = this.action.inputs.map(param => `${param.name}: ${param.type}, `).join('')
		
		this.prefix = `${this.imports}\n` +
		this.getVariableDeclarationsLine({
			output: `{ ${this.options.bo.outputs.map(o => `${o.name}: (data: ${o.outputTsType || 'any'}) => void`).join(',')} }`,
			$event: 'Event',
		}) + '\n' +
		`(async function (this: {${thisActionsDef}}, ${params}$context: Sys.Types.ScreenActionContext) {`
		this.postfix = '})'
	}

	get executionContext(): ExecutionContext {
		return new ExecutionContext('screenAction')
	}


	get prefixHiddenLineCount(): number { return 3 }
	get postfixHiddenLineCount(): number { return 1 }
}

export class ScreenVariableDefaultContext extends GenericVariableDefaultContext<Screen> {
	constructor(public options: CodeContextOptions<Screen>, public variable: VariableDefinition) {
		super(options, variable, options.bo.variableDefinitions, [], 'screenDisplay')
	}
}

export class ScreenEventContext extends CodeContext<Screen> {
	constructor(public options: CodeContextOptions<Screen>, public parameter: Parameter, public block: Block) {
		super(options)
		this.setPrePostfix()
	}

	setPrePostfix(): void {
		if(!this.parameter || !this.block) return // not yet set in super constructor

		const variableScope = this.block.getVariableScope(true) || {}
		const variableDeclarations = Object.keys(variableScope).map(varName => `declare const ${varName}: ${variableScope[varName]}`).join('; ')

		this.prefix = `${this.imports}\n`
			+ `${variableDeclarations}\n`
			+ `(async function (this: never): Promise<${this.parameter.type || 'any'}> { return (`
		this.postfix = ')})'
		this.tsReturnType = `Promise<${this.parameter.type}>`
	}

	get executionContext(): ExecutionContext {
		return new ExecutionContext('screenDisplay')
	}

	get prefixHiddenLineCount(): number { return 3 }
	get postfixHiddenLineCount(): number { return 1 }
}

export class ScreenValidationContext extends CodeContext<Screen> {
	constructor(public options: CodeContextOptions<Screen>,
		public validationRule: FormValidationRule,
		public block: Block
	) {
		super(options)

		this.setPrePostfix()
	}

	setPrePostfix(): void {
		if(!this.validationRule || !this.block) return // not yet set in super constructor

		this.scopeVariables = this.block.getVariableScopeAsParameters(true)

		const factory = ValidationManager.get(this.validationRule.validationType)
		if(!factory) {
			console.error(` ValidationFactory not found for type ${this.validationRule.validationType} at block ${this.block.id}`)
			return
		}
		this.tsReturnType = factory.configType ?? 'never'

		this.prefix = `${this.imports}\n` +
			(this.scopeVariables.length ? `const ${this.scopeVariables.map(v => v.getSignature(false)).join(', ')}` : '') + '\n' +
			`async function (): ${this.tsReturnType} {`
		this.postfix = '}'
	}

	get executionContext(): ExecutionContext {
		return new ExecutionContext('screenDisplay')
	}

	get prefixHiddenLineCount(): number { return 3 }
	get postfixHiddenLineCount(): number { return 1 }
}


// SERVERDATASTORE -----------------------------
export class ServerDataStoreProcedureContext extends CodeContext<ServerDataStore> {
	constructor(public options: CodeContextOptions<ServerDataStore>, public procedure: ServerDataStoreProcedure) {
		super(options)
		this.setPrePostfix()
	}

	setPrePostfix(): void {
		if(!this.procedure) return // not yet set in super constructor

		this.prefix = `CREATE OR REPLACE ${this.procedure.type.toUpperCase()} __PROCEDURE_NAME__ AS BEGIN`
		this.postfix = 'END'
		this.codeLanguage = 'sql'
	}

	get executionContext(): ExecutionContext {
		return new ExecutionContext('calculation')
	}

	get prefixHiddenLineCount(): number { return 1 }
	get postfixHiddenLineCount(): number { return 1 }
}

export class ServerDataStoreViewContext extends CodeContext<ServerDataStore> {
	constructor(public options: CodeContextOptions<ServerDataStore>, public view: ServerDataStoreView) {
		super(options)
		this.setPrePostfix()
	}

	setPrePostfix(): void {
		if(!this.view) return // not yet set in super constructor

		this.prefix = `CREATE OR REPLACE VIEW __VIEW_NAME__ AS BEGIN`
		this.postfix = 'END'
		this.codeLanguage = 'sql'
	}

	get executionContext(): ExecutionContext {
		return new ExecutionContext('calculation')
	}

	get prefixHiddenLineCount(): number { return 1 }
	get postfixHiddenLineCount(): number { return 1 }
}


// STATICENTITY -----------------------------
export class StaticEntityPropertyContext extends CodeContext<StaticEntity> {
	constructor(public options: CodeContextOptions<StaticEntity>, public property: StaticEntityProperty) {
		super(options)
		this.setPrePostfix()
		// this.theme = property.type == 'string' ? 'vs' : 'vs-dark'
	}

	setPrePostfix(): void {
		if(!this.property) return // not yet set in super constructor

		const executionContext = this.executionContext
		const thisType = executionContext.isReadOnly() ? `DeepReadonly<${this.bo.getQualifiedName()}>` : this.bo.getQualifiedName()
		
		this.prefix = [
			this.imports,
			`(function (this: never): string { return (`,
		].join('\n')
		this.postfix = ')})'
		this.tsReturnType = this.property.type
	}

	get executionContext(): ExecutionContext {
		return new ExecutionContext('calculation')
	}

	get prefixHiddenLineCount(): number { return 2 }
	get postfixHiddenLineCount(): number { return 1 }
}

export class StaticEntityDynamicCodeContext extends CodeContext<StaticEntity> {
	constructor(public options: CodeContextOptions<StaticEntity>) {
		super(options)
		this.setPrePostfix()
	}

	setPrePostfix(): void {
		const inputs = this.bo.inputs.map(i => i.getSignature(true))

		this.prefix = [
			this.imports,
			`async function optionList(${inputs.join(', ')}): Promise<This.${this.bo.boId}.Entry[]> {`,
		].join('\n')
		this.postfix = '}'
	}

	get executionContext(): ExecutionContext {
		const executionContextName = [...StaticEntitySources].find(e => e[0] == this.bo.source)?.[2] ?? 'server'
		return new ExecutionContext(executionContextName)
	}

	get prefixHiddenLineCount(): number { return 1 }
	get prefixDisabledLineCount(): number { return 2 }
	get postfixDisabledLineCount(): number { return 1 }
}


// PROCESS -----------------------------
export class ProcessProcessVariableDefaultContext extends GenericVariableDefaultContext<Process> {
	constructor(public options: CodeContextOptions<Process>, public variable: VariableDefinition) {
		super(options, variable, options.bo.processVariableDefinitions, [], 'server')
	}
}

export class ProcessTokenVariableDefaultContext extends GenericVariableDefaultContext<Process> {
	constructor(public options: CodeContextOptions<Process>, public variable: VariableDefinition) {
		super(options, variable, options.bo.tokenVariableDefinitions, options.bo.processVariableDefinitions, 'server')
	}
}


// TRIGGER -----------------------------
export class TriggerMailFilterContext extends CodeContext<Trigger> {
	setPrePostfix(): void {

		this.prefix = `${this.imports}\n`
			+ `async function shouldConsumeMail(mail: Sys.Types.Mail): Promise<boolean> {`
		this.postfix = '}'
	}

	get executionContext(): ExecutionContext {
		return new ExecutionContext('server')
	}

	get prefixHiddenLineCount(): number { return 1 }
	get prefixDisabledLineCount(): number { return 2 }
	get postfixDisabledLineCount(): number { return 1 }
}

export class TriggerActionContext extends CodeContext<Trigger> {
	setPrePostfix(): void {
		const variables: string[] = []
		if(this.bo.triggerType == 'mail') {
			variables.push('mail: Sys.Types.Mail')
		}

		this.prefix = `${this.imports}\n`
			+ `async function onTriggered(${variables.join(', ')}) {`
		this.postfix = '}'
	}

	get executionContext(): ExecutionContext {
		return new ExecutionContext('server')
	}

	get prefixHiddenLineCount(): number { return 1 }
	get prefixDisabledLineCount(): number { return 2 }
	get postfixDisabledLineCount(): number { return 1 }
}