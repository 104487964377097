import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { BoVisitor } from '@shared/bos/bo-visitor'
import { Block } from '..'
import { TabGroupTabBlock } from './tab-group-tab-block'
import type { EventType, TsCodeType } from '@shared/types'
import { ScreenEditorInput } from '../decorators/screen-editor-input'

export const AnimationDurationMsDefault = 300

@jsonObject({ name: 'TabGroupBlock' })
export class TabGroupBlock extends Block {
	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'code',
		order: 3,
		label: 'Selected tab ID',
		tooltip: () => ({ text: 'Only used if you want to programmatically set or get the selected tab' }),
		codeLanguage: 'ts',
		tsReturnType: () => 'string',
		editorSize: 'singleline',
		isBinding: true,
		isMandatory: () => false,
	})
	selectedTabIdCode: string = ''

	@jsonMember(String)
	@ScreenEditorInput({
		order: 1,
		label: `Animation duration (ms; default ${AnimationDurationMsDefault})`,
		inputType: 'code',
		codeLanguage: 'ts',
		tsReturnType: () => 'number',
		editorSize: 'singleline',
		isBinding: false,
	})
	animationDurationMsCode: TsCodeType = ''

	@ScreenEditorInput({
		inputType: 'checkbox',
		order: 2,
		label: 'Stretch tabs',
	})
	@jsonMember(Boolean) stretchTabs: boolean = false

	constructor(init: Partial<TabGroupBlock> = {}) {
		super()
		this.init(init)
	}

	static createNewForEditor(): Block {
		return new this({
			children: [
				TabGroupTabBlock.createNewForEditor(),
			]
		})
	}

	canHaveChild(child: Block): boolean {
		return child instanceof TabGroupTabBlock
	}

	getEditorCategory(): string {
		return 'Screen Structure'
	}

	get allowedEventTypes(): EventType[] {
		return [
			{ name: 'selectedIndexChange' },
		]
	}
}
