import type { HtmlType, TsCodeType } from './../../types'
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { ScreenEditorInput } from '../decorators/screen-editor-input'
import { Block } from '..'

@jsonObject({ name: 'ExpansionPanelBlock' })
export class ExpansionPanelBlock extends Block {
	@jsonMember(String)
	@ScreenEditorInput<ExpansionPanelBlock>({
		inputType: 'code',
		order: 1,
		label: 'Title text',
		codeLanguage: 'ts',
		editorSize: 'singleline',
		isBinding: false,
		tsReturnType: () => 'string',
		isMandatory: () => true,
		alternativeContentAreaName: 'title',
	})
	titleCode: string = `'Title'`

	@jsonMember(String)
	@ScreenEditorInput<ExpansionPanelBlock>({
		inputType: 'code',
		order: 2,
		label: 'Description',
		codeLanguage: 'ts',
		editorSize: 'singleline',
		isBinding: false,
		tsReturnType: () => 'string',
		alternativeContentAreaName: 'description',
	})
	descriptionCode: string = ``

	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'code',
		order: 3,
		label: 'Is open by default',
		tooltip: () => ({ text: 'State of the panel (open or closed) is not saved back' }),
		codeLanguage: 'ts',
		tsReturnType: () => 'boolean',
		editorSize: 'singleline',
		isBinding: false,
		isMandatory: () => true,
	})
	isOpenCode?: TsCodeType = 'true'

	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'code',
		order: 3,
		label: 'Show toggle',
		codeLanguage: 'ts',
		tsReturnType: () => 'boolean',
		editorSize: 'singleline',
		isBinding: false,
		isMandatory: () => true,
	})
	showToggleCode?: TsCodeType = 'true'

	@jsonMember
	@ScreenEditorInput({
		inputType: 'checkbox',
		order: 4,
		label: 'Load content lazily',
		tab: 'Advanced',
	})
	loadLazily: boolean = false

	getEditorCategory(): string {
		return 'Screen Structure'
	}
}
