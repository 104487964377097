import { BoReference } from '@shared/bos/bo-reference';
import { DataUtil } from '@shared/util/data-util'
import { JsonMappable } from './../data/json-mappable'
import type {
	ModuleIdType,
	BusinessObjectIdType,
} from '../types'
import { jsonMember, jsonObject, toJson } from 'typedjson'
import { SystemSettingId, SystemSettings } from './system-settings'

@jsonObject
@toJson
export class Setting<T extends Record<string, any> = Record<string, any>> implements JsonMappable {
	@jsonMember(String)
	get __type(): string { return 'Setting' }
	set __type(_) { /*ignore*/ }

	@jsonMember(String)
	moduleId: ModuleIdType = ''
	
	@jsonMember(String)
	settingId: BusinessObjectIdType = ''
	
	@jsonMember({ constructor: Object })
	configuredValue: T = {} as T

	@jsonMember({ constructor: Object })
	defaultValue: T = {} as T
	
	private _cachedValuePreview?: { key: string, value: string }[]

	get effectiveValue(): T { return DataUtil.mergeObjectsWhereNotEmpty(this.defaultValue, this.configuredValue) as T }

	constructor(init?: Partial<Setting>) {
		DataUtil.assignCommonProperties(this, init)
		if(init?.configuredValue) this.configuredValue = { ...this.configuredValue }
		if(init?.defaultValue) this.defaultValue = init.defaultValue as T
	}

	getQualifiedName() {
		return `${this.moduleId}.${this.settingId}`
	}

	getBoReference() {
		return new BoReference({
			moduleId: this.moduleId,
			boId: this.settingId,
			boType: 'ConfigurationItem'
		})
	}

	cleanupJsonValue() {
		for(const [key, value] of Object.entries(this.configuredValue)) {
			if(value == undefined || value === '') delete this.configuredValue[key]
		}
	}

	getValuePreview() {
		const keys = [...new Set([
			...Object.keys(this.defaultValue),
			...Object.keys(this.configuredValue),
		])]
		return keys.map(key => {
			const value = this.configuredValue[key] == undefined || this.configuredValue[key] == ''
				? (this.defaultValue[key] + ' (default)')
				: this.configuredValue[key]
			return `${key}: ${String(value)}`
		})
	}

	getValuePreview2() {
		if(!this._cachedValuePreview) {
			const keys = [...new Set([
				...Object.keys(this.defaultValue),
				...Object.keys(this.configuredValue),
			])]
			this._cachedValuePreview = keys.map(key => {
				const value = this.configuredValue[key] == undefined || this.configuredValue[key] == ''
					? (this.defaultValue[key] + ' (default)')
					: this.configuredValue[key]
				return { key, value } as const
			})
		}
		return this._cachedValuePreview!
	}

	isSystemSetting<K extends SystemSettingId>(desiredSettingId: K): this is Setting<SystemSettings[K]> {
		return this.moduleId == 'System' && this.settingId == desiredSettingId
	}
}
